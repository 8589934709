import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { EVENT_TYPES_URL } from '../../constants/urls';
import EventTypeType from '../../domain/eventType';

async function getEventTypes() {
  const requestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
    const response = await axios.get<EventTypeType[]>(EVENT_TYPES_URL, requestConfig);
    return response.data;
}
function useGetEventTypes() {
  const useQueryConfig = {
    staleTime: 100000, // Cache for this long, in ms
  };
  return useQuery(
    ['getEventTypes'],
    () => getEventTypes(),
    useQueryConfig,
  );
}

export {useGetEventTypes}