import { ICellRendererParams } from 'ag-grid-community';
import DefaultTooltip, { getCellId } from '../DefaultTooltip/DefaultTooltip';
import React from 'react';

type FcpNameWithAlertIconsProps = ICellRendererParams;
export const FcpNameWithAlertIcons = (props: FcpNameWithAlertIconsProps) => {
  let adaptedIcon = undefined;
  if (props.data.isAdapted) {
    adaptedIcon = <DefaultTooltip id={getCellId(props) + '-adapted'} tooltipText={'Is Adapted'}>
      <i
        style={{
          textDecoration: 'underline dotted',
          color: 'grey',
        }}
        className='bi bi-tools'
      />
    </DefaultTooltip>;
  }
  let deletedIcon = undefined;
  if (!props.data.createdByCore && props.data.createdByCoreVersion) {
    deletedIcon = <DefaultTooltip id={getCellId(props) + '-adapted'} tooltipText={'This used to be a core assignment but it was deleted'}>
      <i
        style={{
          textDecoration: 'underline dotted',
          color: 'red',
        }}
        className='bi bi-trash-fill'
      />
    </DefaultTooltip>;
  }
  let outdatedIcon = undefined;
  if (props.data.isOutdatedCoreVersion) {
    outdatedIcon = <DefaultTooltip id={getCellId(props) + '-outdated'} tooltipText={'Is Outdated'}>
      <i
        style={{
          textDecoration: 'underline dotted',
          color: '#bf9300',
        }}
        className='bi bi-exclamation-triangle-fill'
      />
    </DefaultTooltip>;
  }
  return (
    <span>{adaptedIcon}{adaptedIcon && ' '}{outdatedIcon}{outdatedIcon && ' '}{deletedIcon}{deletedIcon && ' '}{props.value}</span>
  );
}

export default FcpNameWithAlertIcons;