import axios from 'axios';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { RECURRING_TASK_SCHEDULES_URL } from '../../../../constants/urls';
import RecurringTaskScheduleType, { RecurringTaskScheduleTypeToSend } from '../../../../domain/recurringTaskSchedule';
import TeamType from '../../../../domain/team';
import FormikController from '../../../../formik/FormikSelect/FormikController';
import { getNameOfCreationUser } from '../../../../helpers/AuditFieldHelper';
import { Mode } from '../../../../state_types/mode';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../FeedbackMessages/FeedbackMessages';
import React, { useState } from 'react';
import { getSimpleModal, ModifyDialogState } from '../../../../helpers/GridComponentHelpers';
import { getFormikReadOnlyField } from '../../../../formik/FormikHelpers';
import { getCallSignWithPositionAndName } from '../../../../helpers/TeamMembershipHelpers';
import DefaultTooltip from '../../../DefaultTooltip/DefaultTooltip';
import { formatDateForAgGridWithYear } from '../../../../helpers/AgGridDateHelpers';
import { useGetTags } from '../../../apis/tags_api';
import { useGetTeamMembersForTeam } from '../../../apis/team_members_api';
import CoreSyncButton from '../../../shared/CoreSyncButton';

type ModifyRecurringTaskScheduleDialogState = {
  isOpen: boolean;
  scheduleToEdit: RecurringTaskScheduleType | null;
};

export interface ModifyRecurringTaskScheduleDialogProps {
  selectedTeam: TeamType;
  state: ModifyDialogState<RecurringTaskScheduleType>;
  onReplaceAdaptedFields: (scheduleToReplace: RecurringTaskScheduleType) => Promise<void>;
  onOk: (updatedScheduleFields: RecurringTaskScheduleTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (scheduleToDelete: RecurringTaskScheduleType) => void;
}

const getMode = (state: ModifyDialogState<RecurringTaskScheduleType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Recurring Task Schedule';
};

const FREQUENCY_OPTIONS = [
  {
    label: 'Yearly',
    value: 'yearly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

const DAYS_TO_COMPLETE = 30;

const ModifyRecurringTaskScheduleDialog = (props: ModifyRecurringTaskScheduleDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  const [isReplacingAdaptations, setIsReplacingAdaptations] = useState(false);

  const handleCancel = () => {
    onCancel();
  };
  // If task to edit is set, assume edit.
  // Otherwise, assume create
  const mode = getMode(state);
  // const task = state.taskToEdit;

  const { isLoading: isLoadingTeamMembers, error: teamMembersError, data: teamMembers, refetch: refetchTeamMembers } = useGetTeamMembersForTeam(props.selectedTeam.id)
  const { isLoading: isLoadingTags, error: tagsError, data: tags, refetch: refetchTags } = useGetTags();
  if (isLoadingTags || isLoadingTeamMembers) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  if (tagsError || teamMembersError) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  if (teamMembers === undefined || tags === undefined) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');

  const teamMemberOptions = teamMembers.map((teamMember) => {
    const optionLabel = getCallSignWithPositionAndName(teamMember);
    return {
      label: optionLabel,
      value: teamMember.id,
    };
  });

  const tagOptions = tags.map((tag) => {
    return {
      label: tag.name,
      // Value is the object itself. This makes it easy for Formik to pass to backend
      // TODO change to id?
      value: tag,
    };
  });

  // TODO should this be in some sort of useState hook?
  let initialStateOfEditableScheduleFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const schedule = state.objectToModify!;

    // Copy only editable fields
    initialStateOfEditableScheduleFields = {
      // Not editable
      // id: task.id,
      frequency: schedule.frequency,
      startDateTime: schedule.startDateTime,
      // Not editable
      // creationUser: task.creationUser,
      // Not editable in the UI, but needed on create, so included
      // TODO now create is separated, is above comment still valid???
      team: schedule.team.id,

      templateTaskToCreate: {
        summaryTemplate: schedule.templateTaskToCreate.summaryTemplate,
        descriptionTemplate: schedule.templateTaskToCreate.descriptionTemplate,
        linkUrlTemplate: schedule.templateTaskToCreate.linkUrlTemplate,
        // Can be undefined, for example when a new FCP is cascaded
        assignedToMember: schedule.templateTaskToCreate.assignedToMember?.id,
        totalPoints: schedule.templateTaskToCreate.totalPoints,
        tags: schedule.templateTaskToCreate.tags,
        // Not editable in the UI, but needed on create, so included
        // TODO now create is separated, is above comment still valid???
        team: schedule.templateTaskToCreate.team.id,
      },
    } as RecurringTaskScheduleTypeToSend;
    const createdByName = getNameOfCreationUser(schedule);
    createdByText = getFormikReadOnlyField('Created by:', createdByName);

    deleteButton = (
      <Button
        id='deleteRecurringTask'
        className='bi-trash-fill mr-auto'
        variant='danger'
        onClick={() => {
          onDeleteIcon(schedule);
        }}
      >
        Delete
      </Button>
    );

    onSubmit = (updatedSchedule: RecurringTaskScheduleTypeToSend | null, { setSubmitting }: any) => {
      console.log('update schedule dialog ok hit', updatedSchedule);
      setTimeout(() => {
        if (updatedSchedule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('updatedSchedule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // update hardcoded values on the UI.
        // TODO is this the best place to put this?
        // I bet it will get lost...
        // Should it be in the backend?
        updatedSchedule.templateTaskToCreate.daysToComplete = DAYS_TO_COMPLETE;

        // TODO make this typescript cast have a validation or something
        onOk(updatedSchedule, schedule.id).then(() => {
          // In case new tags were created
          refetchTags();
          refetchTeamMembers();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableScheduleFields = {
      frequency: '',
      startDateTime: '',
      team: props.selectedTeam.id,

      templateTaskToCreate: {
        summaryTemplate: '',
        descriptionTemplate: '',
        linkUrlTemplate: '',
        assignedToMember: undefined,
        totalPoints: undefined,
        tags: [],
        team: props.selectedTeam.id,
      },
    } as RecurringTaskScheduleTypeToSend;
    onSubmit = (newSchedule: RecurringTaskScheduleTypeToSend, { setSubmitting }: any) => {
      setTimeout(() => {
        if (newSchedule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new schedule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // initialize hardcoded values on the UI.
        // TODO is this the best place to put this?
        // I bet it will get lost...
        // Should it be in the backend?
        newSchedule.templateTaskToCreate.daysToComplete = DAYS_TO_COMPLETE;

        // TODO make this typescript cast have a validation or something
        onOk(newSchedule, null).then(() => {
          // In case new tags were created
          refetchTags();
          refetchTeamMembers();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    createdByText = null;
  }

  const RecurringTaskScheduleFormSchema = Yup.object().shape({
    frequency: Yup.string().nullable().required('Required'),
    startDateTime: Yup.date().required('Required'),

    templateTaskToCreate: Yup.object({
      summaryTemplate: Yup.string().required('Required'),
      descriptionTemplate: Yup.string().optional(),
      linkUrlTemplate: Yup.string().optional(),
      assignedToMember: Yup.number().nullable(),
      totalPoints: Yup.number().required('Required'), // TODO make integer only
      // TODO tags?
    }),
  });

  const replaceAdaptedFields = () => {
    setIsReplacingAdaptations(true)
    if (state.objectToModify) {
      props.onReplaceAdaptedFields(state.objectToModify).then(() => {
        setIsReplacingAdaptations(false)
        onCancel();
      })
    }
  };

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel} id={'modifyRecurringTaskScheduleModal'}>
      {
        <Formik
          initialValues={initialStateOfEditableScheduleFields}
          validationSchema={RecurringTaskScheduleFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: any) => {
            const isAdapted = state.objectToModify?.isAdapted;
            const isOutdatedVersion = state.objectToModify?.isOutdatedCoreVersion;
            const createdByCore = state.objectToModify?.createdByCore;
            const createdByCoreVersion = state.objectToModify?.createdByCoreVersion;

            const getAdaptedIcon = (newestVersion: string | number, adaptedFrom: string | number | undefined, isAdapted: boolean | undefined) => {
              if (!isAdapted) return null;
              return <DefaultTooltip id={'adapted'} tooltipBodyElement={<div>
                <div><strong>Adapted from global FCP</strong></div>
                {adaptedFrom && (adaptedFrom !== newestVersion) && <div><strong>Adapted From</strong>: {adaptedFrom}</div>}
                <div><strong>Newest Version</strong>: {newestVersion}</div>
              </div>}>
                <i
                  style={{
                    textDecoration: 'underline dotted',
                    color: 'grey',
                  }}
                  className='bi bi-tools'
                />
              </DefaultTooltip>;
            };
            const adaptations = state.objectToModify?.adaptations;

            let startDateTimeAdaptedIcon = undefined;
            if(createdByCore && adaptations?.startDateTime) {
              let adaptedFromCoreVersion = undefined;
              if(createdByCoreVersion) {
                adaptedFromCoreVersion = formatDateForAgGridWithYear(createdByCoreVersion.startDateTime, state.objectToModify!.team.timezone);
              }
              startDateTimeAdaptedIcon = getAdaptedIcon(formatDateForAgGridWithYear(createdByCore.startDateTime, state.objectToModify!.team.timezone),
                adaptedFromCoreVersion, adaptations?.startDateTime);
            }
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <div>{getModalTitle(mode)}</div>
                    <CoreSyncButton
                      isAdapted={Boolean(isAdapted)}
                      isOutdatedVersion={Boolean(isOutdatedVersion)}
                      createdByCore={createdByCore}
                      isUpdatingAdaptations={isReplacingAdaptations}
                      onReplaceAdaptedFields={replaceAdaptedFields}
                      isSubmitting={formikProps.isSubmitting}
                      tooltipBody={
                        <div>
                          <h5>Will change to the following</h5>
                          <div><b>Summary</b>: {createdByCore?.summaryTemplate}</div>
                          <div><b>Frequency</b>: {createdByCore?.frequency}</div>
                          <div><b>Recur Date</b>: {createdByCore?.startDateTime ? formatDateForAgGridWithYear(createdByCore.startDateTime, props.selectedTeam.timezone) : 'N/A'}</div>
                          <div><b>Days To Complete</b>: {createdByCore?.daysToComplete}</div>
                          <div><b>Description</b>: {createdByCore?.descriptionTemplate}</div>
                          <div><b>Link</b>: {createdByCore?.linkUrlTemplate}</div>
                          <div><b>Weight</b>: {createdByCore?.totalPoints}</div>
                        </div>
                      }
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Only show createdByText on editing */}
                  {createdByText}
                  <FormikController
                    testKey='frequency'
                    control='select'
                    name='frequency'
                    label={
                      <span>Frequency {createdByCore && getAdaptedIcon(createdByCore.frequency, createdByCoreVersion?.frequency, adaptations?.frequency)}</span>}
                    options={FREQUENCY_OPTIONS}
                  />
                  <FormikController
                    control='date'
                    name='startDateTime'
                    label={
                      <span>Start Date Time {startDateTimeAdaptedIcon}</span>}
                    timezone={props.selectedTeam.timezone}
                  />
                  <FormikController control='input' name='templateTaskToCreate.summaryTemplate'
                                    label={
                                      <span>Summary {createdByCore && getAdaptedIcon(createdByCore.summaryTemplate, createdByCoreVersion?.summaryTemplate, adaptations?.summaryTemplate)}</span>} />
                  <FormikController
                    control='input'
                    name='templateTaskToCreate.descriptionTemplate'
                    label={
                      <span>Description (optional) {createdByCore && getAdaptedIcon(createdByCore.descriptionTemplate, createdByCoreVersion?.descriptionTemplate, adaptations?.descriptionTemplate)}</span>}
                    />
                  <FormikController control='input' name='templateTaskToCreate.linkUrlTemplate'
                                    label={
                                      <span>Link (optional) {createdByCore && getAdaptedIcon(createdByCore.linkUrlTemplate, createdByCoreVersion?.linkUrlTemplate, adaptations?.linkUrlTemplate)}</span>} />
                  <FormikController
                    control='select'
                    name='templateTaskToCreate.assignedToMember'
                    label='Assigned To'
                    options={teamMemberOptions}
                  />
                  <FormikController control='input' name='templateTaskToCreate.totalPoints'
                                    label={
                                      <span>Weight {createdByCore && getAdaptedIcon(createdByCore.totalPoints, createdByCoreVersion?.totalPoints, adaptations?.totalPoints)}</span>} />
                  {/* 'startingOptions' because we are allowing the creation of new tags */}
                  <FormikController
                    control='tags'
                    name='templateTaskToCreate.tags'
                    label='Tags (optional)'
                    startingOptions={tagOptions}
                  />
                </Modal.Body>
                <Modal.Footer>
                  {/* Only show delete button on editing */}
                  {deleteButton}
                  <Button variant='secondary' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button id='recurringTaskSubmit' variant='primary' type='submit' disabled={formikProps.isSubmitting || isReplacingAdaptations}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      }
    </Modal>
  );
};

function defaultHandleModifyRecurringTaskScheduleDialogOk(
  modifiedSchedule: RecurringTaskScheduleTypeToSend,
  idToUpdate: number | null,
  setModifyRecurringTaskScheduleDialogState: (state: any) => void,
  refetchSchedules: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  let promise;
  if (idToUpdate !== null) {
    console.log('Updating schedule ' + idToUpdate, modifiedSchedule);
    // POST is for new stuff, PUT is for replacing task (must have ALL fields)
    // This uses PATCH, which loads old task and only updates fields you passed
    // https://stackoverflow.com/a/24241955/13815107
    promise = axios.patch(RECURRING_TASK_SCHEDULES_URL + '/' + idToUpdate + '/', modifiedSchedule).then((response) => {
      setModifyRecurringTaskScheduleDialogState({
        isOpen: false,
        scheduleToEdit: null,
      });
      addFeedbackMessage({
        key: createFeedbackMessageKey('recurringTaskSchedule', 'change', idToUpdate),
        status: 'success',
        messageBody: <span>Recurring task schedule updated successfully.</span>,
      });
      refetchSchedules();
    });
  } else {
    console.log('Creating schedule', modifiedSchedule);
    if (modifiedSchedule.id) {
      console.error('Creating a schedule but sending an id. Was this meant to be a modify?');
      promise = Promise.reject('Creating a schedule but sending an id. Was this meant to be a modify?');
    } else {
      promise = axios.post(RECURRING_TASK_SCHEDULES_URL + '/', modifiedSchedule).then((response) => {
        setModifyRecurringTaskScheduleDialogState({
          isOpen: false,
          scheduleToEdit: null,
        });
        addFeedbackMessage({
          key: createFeedbackMessageKey('recurringTaskSchedule', 'create'),
          status: 'success',
          messageBody: <span>Recurring task schedule created successfully.</span>,
        });
        refetchSchedules();
      });
    }
  }
  return promise;
}

export default ModifyRecurringTaskScheduleDialog;
export { defaultHandleModifyRecurringTaskScheduleDialogOk };
export type { ModifyRecurringTaskScheduleDialogState };
