import AccountType from './account';
import { ObjectWithActions, ObjectWithId } from './shared';
import TeamType from './team';
import TemplateTaskType, { TemplateTaskTypeToSend } from './templateTask';
import CoreRecurringTaskScheduleType from './coreRecurringTaskSchedule';

type RecurringTaskScheduleType = ObjectWithId &
  ObjectWithActions & {
    id: number;
    frequency: string;
    startDateTime: string;
    creationUser: AccountType;
    team: TeamType;
    templateTaskToCreate: TemplateTaskType;

    createdByCore: CoreRecurringTaskScheduleType;
    createdByCoreVersion: CoreRecurringTaskScheduleType;
    isAdapted: boolean;
    adaptations: {
      summaryTemplate: boolean;
      linkUrlTemplate: boolean;
      descriptionTemplate: boolean;
      totalPoints: boolean;
      daysToComplete: boolean;
      frequency: boolean;
      startDateTime: boolean;
    }
    isOutdatedCoreVersion: boolean;

    actions: string[];
  };

type RecurringTaskScheduleTypeToSend = {
  // RecurringTaskSchedule
  id?: number;
  frequency: string; // '' instead of undefined, only '' triggers formik/yup's .required('Message')
  startDateTime: string; // '' instead of undefined, only '' triggers formik/yup's .required('Message')
  creationUser?: AccountType;
  team?: number;
  actions?: string[];

  templateTaskToCreate: TemplateTaskTypeToSend;
};

function convertToTypeToSend(recurringTaskSchedule: RecurringTaskScheduleType): RecurringTaskScheduleTypeToSend {
  // Convert objects into ids while preserving the other fields
  const recurringTaskScheduleToSend = {
    ...recurringTaskSchedule,
    team: recurringTaskSchedule.team.id,
    templateTaskToCreate: {
      ...recurringTaskSchedule.templateTaskToCreate,
      team: recurringTaskSchedule.templateTaskToCreate.team.id,
      // Sometimes undefined/null, especially after installing a new FCP
      assignedToMember: recurringTaskSchedule.templateTaskToCreate.assignedToMember?.id,
    },
  } as RecurringTaskScheduleTypeToSend;
  return recurringTaskScheduleToSend;
}

export default RecurringTaskScheduleType;
export type { RecurringTaskScheduleTypeToSend };
export { convertToTypeToSend };
