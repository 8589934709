import { AgGridColumnProps, AgGridReact } from 'ag-grid-react';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import TeamType from '../../../domain/team';
import TriggerRuleType, { convertToTypeToSend } from '../../../domain/triggerRule';
import TaskGridSummary from '../../tasks/TaskGridSummary/TaskGridSummary';
import TaskGridTags from '../../tasks/TaskGridTags/TaskGridTags';
import { doActionsContain, hasPermission } from '../../../helpers/PermissionHelper';
import PermissionType from '../../../domain/permission';
import ModifyTriggerRuleDialog, {
  defaultHandleModifyTriggerRuleDialogOk,
} from '../ModifyTriggerRuleDialog/ModifyTriggerRuleDialog';
import { TRIGGER_RULE_PERMISSIONS_URL, TRIGGER_RULE_URL } from '../../../constants/urls';
import { RowClassParams, RowDoubleClickedEvent, ValueGetterParams } from 'ag-grid-community';
import {
  defaultOnGridReady,
  getAssignedToString,
  getDefaultColDef,
  getDefaultStatusBar,
  getDefaultUseQueryConfig,
  useGridComponentStates,
} from '../../../helpers/GridComponentHelpers';
import FieldCoreProcessType from '../../../domain/fieldCoreProcess';
import styles from '../../trigger/TriggerRuleGrid/TriggerRuleGrid.module.scss';
import { MemberSelect } from '../../members/MemberSelect/MemberSelect';
import { createFeedbackMessageKey, FeedbackMessage } from '../../FeedbackMessages/FeedbackMessages';
import TeamMemberType from '../../../domain/teamMember';
import GenericGridActions from '../../GenericGridActions/GenericGridActions';
import GenericDeleteDialog from '../../GenericDeleteDialog/GenericDeleteDialog';
import GenericRequestChangeDialog, {
  genericHandleRequestChangeOk,
} from '../../GenericRequestChangeDialog/GenericRequestChangeDialog';
import RequestedChangeToSend from '../../GenericRequestChangeDialog/requestedChange';
import { makeInQueryFromList } from '../../../helpers/ObjectIdHelpers';
import TagType from '../../../domain/tag';
import { replaceAdaptedTriggerRuleFieldsWithCore } from '../../apis/trigger_rule_api';

type TriggerRuleGridProps = {
  selectedTeam: TeamType;
  fieldCoreProcesses?: FieldCoreProcessType[];
  memberQuickSelect?: boolean;
  hideCreateButton?: boolean;
  idToFocusOn?: number;
};

const getTriggerRuleColumns = (
  openModifyDialog: (objectToModify: TriggerRuleType) => void,
  openRequestChangeDialog: (objectToModify: TriggerRuleType) => void,
  team: TeamType,
  memberQuickSelect: boolean | undefined,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
): AgGridColumnProps[] => {
  let assignedToColumn;
  if (memberQuickSelect) {
    assignedToColumn = {
      headerName: 'Assigned To',
      width: 200,
      field: 'templateTaskToCreate.assignedToMember',
      filterValueGetter: (params: ValueGetterParams) => {
        const schedule = params.data as TriggerRuleType;
        const assignedTo = schedule?.templateTaskToCreate?.assignedToMember;
        return getAssignedToString(assignedTo);
      },
      cellRenderer: 'assignedToQuickSelectRenderer',
      cellRendererParams: {
        selectedTeam: team,
        onChange: (newTeamMember: TeamMemberType | undefined, oldTriggerRule: TriggerRuleType) => {
          // I would love to use this, but grid API isn't available at the start
          // if(gridApi) {
          //   gridApi.showLoadingOverlay()
          // }
          const modifiedTriggerRuleToSend = convertToTypeToSend(oldTriggerRule);
          modifiedTriggerRuleToSend.templateTaskToCreate.assignedToMember = newTeamMember?.id;
          // We could also wrap this in react-query's useMutation, but that seems overkill
          const response = axios
            .patch(TRIGGER_RULE_URL + '/' + oldTriggerRule.id + '/', modifiedTriggerRuleToSend)
            .then(() => {
              // TODO handle errors
              addFeedbackMessage({
                key: createFeedbackMessageKey('triggerRule', 'changeTeamMember', oldTriggerRule.id),
                status: 'success',
                messageBody: <span>Team member changed successfully.</span>,
              });
              refetch();
            });
          return response;
        },
      },
      cellStyle: { overflow: 'visible' },
    };
  } else {
    assignedToColumn = {
      headerName: 'Assigned To',
      width: 200,
      // field: 'templateTaskToCreate.assignedToMember.callSign',
      valueGetter: (params: ValueGetterParams) => {
        const triggerRule = params.data as TriggerRuleType;
        const assignedTo = triggerRule.templateTaskToCreate.assignedToMember;
        return getAssignedToString(assignedTo);
      },
    };
  }
  return [
    {
      field: 'createOnEventType.name',
      headerName: 'Event',
      width: 120,
    },
    {
      field: 'templateTaskToCreate.summaryTemplate',
      headerName: 'Summary Template',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
    },
    {
      field: 'fieldCoreProcess.name',
      headerName: 'FCP',
      width: 90,
    },
    assignedToColumn,
    {
      field: 'templateTaskToCreate.descriptionTemplate',
      headerName: 'Description Template',
      width: 150,
    },
    {
      field: 'templateTaskToCreate.tags',
      headerName: 'Tags',
      cellRenderer: 'tagsRenderer',
      width: 120,
      filterParams: {
        valueGetter: (params: ValueGetterParams) => {
          return params.data.templateTaskToCreate?.tags
            .map((tag: TagType) => {
              return tag.name;
            })
            .join(', ');
        },
      },
    },
    {
      field: 'templateTaskToCreate.totalPoints',
      headerName: 'Weight',
      width: 90,
    },
    {
      headerName: 'Actions',
      width: 90,
      cellRenderer: 'actionsRenderer',
      cellRendererParams: {
        onEditClick: (ruleToEdit: TriggerRuleType) => {
          openModifyDialog(ruleToEdit);
        },
        onRequestChangeClick: (ruleToRequestChange: TriggerRuleType) => {
          openRequestChangeDialog(ruleToRequestChange);
        },
      },
    },
  ];
};

const TriggerRuleGrid = (props: TriggerRuleGridProps) => {
  let gridComponentStates = useGridComponentStates<TriggerRuleType>();

  const triggerRuleRequestConfig = {
    params: {
      team: props.selectedTeam.id,
      // __in requires extra implementation by backend, check before copy/pasting!
      created_by_core__field_core_process__in: makeInQueryFromList(props.fieldCoreProcesses),
    },
  } as AxiosRequestConfig;
  const {
    isRefetching: isRefetchingRules,
    error: rulesError,
    data: rulesData,
    refetch: refetchRules,
  } = useQuery(
    ['getTriggerRuleForTeam', JSON.stringify(triggerRuleRequestConfig)],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridComponentStates.gridApi) {
        gridComponentStates.gridApi.showLoadingOverlay();
      }
      return axios.get<TriggerRuleType[]>(TRIGGER_RULE_URL, triggerRuleRequestConfig);
    },
    getDefaultUseQueryConfig<TriggerRuleType>(
      gridComponentStates.gridApi,
      gridComponentStates.openModifyDialog,
      props.idToFocusOn,
    ),
  );

  const [columnDefs] = React.useState(
    getTriggerRuleColumns(
      gridComponentStates.openModifyDialog,
      gridComponentStates.openRequestChangeDialog,
      props.selectedTeam,
      props.memberQuickSelect,
      refetchRules,
      gridComponentStates.addFeedbackMessage,
    ),
  );

  const triggerRulePermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const {
    error: schedulePermissionsError,
    data: schedulePermissionsData,
    isLoading: isPermissionsLoading,
  } = useQuery(['getTriggerRulePermissions', JSON.stringify(triggerRulePermissionsRequestConfig)], () => {
    return axios.get<PermissionType[]>(TRIGGER_RULE_PERMISSIONS_URL, triggerRulePermissionsRequestConfig);
  });

  if (rulesError || schedulePermissionsError) return <p>Error!</p>;

  const triggerRules = rulesData?.data;
  const schedulePermissions = schedulePermissionsData?.data;

  if (!isPermissionsLoading && (!schedulePermissions || schedulePermissions.length === 0)) {
    return <p>No permission for trigger rules.</p>;
  }

  const handleModifyDialogDeleteIcon = (updatedSchedule: TriggerRuleType) => {
    gridComponentStates.closeModifyDialog();
    gridComponentStates.openDeleteDialog(updatedSchedule);
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    const schedule = event.data as TriggerRuleType;
    if (doActionsContain(schedule, 'change')) {
      gridComponentStates.openModifyDialog(schedule);
    }
  };

  const onCreateClick = () => {
    // Null for new object
    gridComponentStates.openModifyDialog(null);
  };

  return (
    <Container fluid className={' d-flex flex-column flex-grow-1'} style={{ height: '100%' }}>
      <Row className="d-flex flex-column flex-grow-1">
        <Col>
          <div
            className="ag-theme-balham"
            style={{
              height: '100%',
              width: '100%',
              minHeight: '200px',
            }}
          >
            <AgGridReact
              onGridReady={(params) => {
                defaultOnGridReady(params, triggerRules, isRefetchingRules, gridComponentStates.setGridApi);
              }}
              frameworkComponents={{
                actionsRenderer: GenericGridActions,
                tagsRenderer: TaskGridTags,
                // dateAndCreationIconRenderer: TaskGridDateAndCreationIcon,
                summaryRenderer: TaskGridSummary,
                assignedToQuickSelectRenderer: MemberSelect,
              }}
              onRowDoubleClicked={handleRowDoubleClick}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              tooltipShowDelay={450}
              defaultColDef={getDefaultColDef()}
              getRowClass={(params: RowClassParams) => {
                const triggerRule = params.data as TriggerRuleType;
                if (
                  !triggerRule.templateTaskToCreate.assignedToMember ||
                  !triggerRule.templateTaskToCreate.assignedToMember.account
                ) {
                  return styles.rowWarning;
                } else {
                  // No classes
                  return;
                }
              }}
              // Normally we pass in row data via api on react-query onSuccess instead.
              // Otherwise this comes through as undefined on user tab switching which breaks loading spinner
              // However, apparently onSuccess doesn't get called when the data was cached
              // That means when you flip Legal to Station Expansion and back to Legal, Legal Cases don't show up
              // Using rowData fixes this, but I'm hoping for a better solution later.
              rowData={triggerRules}
              statusBar={getDefaultStatusBar()}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {schedulePermissions && hasPermission(schedulePermissions, 'add_triggerrule') && !props.hideCreateButton && (
            <Button variant="primary" onClick={onCreateClick}>
              Create a trigger rule <i className="bi bi-plus" />
            </Button>
          )}
        </Col>
      </Row>
      <GenericDeleteDialog
        state={gridComponentStates.deleteDialogState}
        onCancel={gridComponentStates.closeDeleteDialog}
        onOk={(idToDelete: number) =>
          handleDeleteTriggerRuleDialogOk(
            idToDelete,
            gridComponentStates.closeDeleteDialog,
            gridComponentStates.disableSubmissionDeleteDialog,
            refetchRules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        objectTypeString="trigger rule"
      />
      <GenericRequestChangeDialog
        state={gridComponentStates.requestChangeDialogState}
        onOk={(requestedChange: RequestedChangeToSend) =>
          genericHandleRequestChangeOk(
            requestedChange,
            gridComponentStates.closeRequestChangeDialog,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onCancel={gridComponentStates.closeRequestChangeDialog}
        objectTypeString={'trigger rule'}
      />
      <ModifyTriggerRuleDialog
        selectedTeam={props.selectedTeam}
        // Should I change how I pass this state somehow?
        state={gridComponentStates.modifyDialogState}
        onReplaceAdaptedFields={(scheduleToReplace) => {
          return replaceAdaptedTriggerRuleFieldsWithCore(scheduleToReplace).then(() => {
            console.log('refetching');
            refetchRules();
          })
        }}
        onCancel={gridComponentStates.closeModifyDialog}
        onOk={(ruleToModify, idToUpdate) =>
          defaultHandleModifyTriggerRuleDialogOk(
            ruleToModify,
            idToUpdate,
            gridComponentStates.closeModifyDialog,
            refetchRules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onDeleteIcon={handleModifyDialogDeleteIcon}
      />
    </Container>
  );
};

function handleDeleteTriggerRuleDialogOk(
  idToDelete: number,
  closeDeleteDialog: () => void,
  disableSubmissionDeleteDialog: () => void,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  disableSubmissionDeleteDialog();
  return axios.delete(TRIGGER_RULE_URL + '/' + idToDelete + '/').then((response) => {
    closeDeleteDialog();
    addFeedbackMessage({
      key: createFeedbackMessageKey('triggerRule', 'delete', idToDelete),
      status: 'success',
      messageBody: <span>Trigger rule deleted successfully.</span>,
    });
    refetch();
  });
}

export default TriggerRuleGrid;
