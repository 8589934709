import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { ChangeEvent, useContext } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { useQuery } from 'react-query';
import { GET_MY_TEAMS_URL } from '../../constants/urls';
import SelectedTeamContext from '../../contexts/SelectedTeamContext';
import TeamType from '../../domain/team';
import TeamMemberType from '../../domain/teamMember';
import styles from './TeamSelect.module.scss';

type TeamSelectProps = {
  loggedInTeamMember: TeamMemberType | null;
};

const TeamSelect = (props: TeamSelectProps) => {
  const selectedTeamContext = useContext(SelectedTeamContext);

  const requestConfig = {
    params: {
      // No params
    },
  } as AxiosRequestConfig;

  const useQueryConfig = {
    onSuccess: (data: AxiosResponse<TeamType[]>) => {
      const teams = data.data;

      let defaultTeam = teams[0];
      if (props.loggedInTeamMember) {
        const loggedInMembersTeamId = props.loggedInTeamMember.team;
        const foundLoggedInTeam = teams.find((team) => {
          return team.id === loggedInMembersTeamId;
        });
        if (foundLoggedInTeam) {
          defaultTeam = foundLoggedInTeam;
        }
      }
      // set default team only when teams change
      // This needs to be here or it is called on every component reload
      //TODO this is a little janky, maybe move getTeams up to App component?
      selectedTeamContext.setSelectedTeam(defaultTeam);
    },
  };
  const { isLoading, error, data } = useQuery(
    ['getTeams', requestConfig],
    () => {
      return axios.get<TeamType[]>(GET_MY_TEAMS_URL, requestConfig);
    },
    useQueryConfig,
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;
  if (data === undefined) return <p>No data</p>;
  if (data.data === undefined || !Array.isArray(data.data)) return <p>Invalid data format</p>;

  // Fill dropdown when user has no team
  const teams = data.data[0] !== undefined ? data.data : [{ id: -1, name: 'User has no team' }];

  const onTeamChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const selectedTeamId = target.value;
    // https://stackoverflow.com/a/53283425
    const selectedTeam = teams.find((team) => team.id === parseInt(selectedTeamId));
    selectedTeamContext.setSelectedTeam(selectedTeam);
  };

  //https://www.pluralsight.com/guides/how-to-get-select-element%27s-value-in-react-bootstrap
  const teamSelectRef = React.createRef<HTMLSelectElement>();

  return (
    <Form.Control
      className={styles.teamSelect}
      as="select"
      ref={teamSelectRef}
      value={selectedTeamContext.selectedTeam?.id}
      onChange={onTeamChange}
    >
      {teams.map((team) => {
        return (
          <option key={team.id} value={team.id}>
            {team.name}
          </option>
        );
      })}
    </Form.Control>
  );
};

export default TeamSelect;
