// Combines Formik with react-select and giving it react-bootstrap styling
// Based on the LAST few examples on https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8
import { ErrorMessage, Field, FieldProps } from 'formik';
import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import { Options, OnChangeValue } from 'react-select';
import CustomErrorMessage from './CustomErrorMessage';

interface GenericOption {
  label: string;
  // I usually use the object itself as the value.
  value: any;
}

interface IdOption {
  label: string;
  // I usually use the object itself as the value.
  value: number;
}


interface FormikSelectProps {
  name: string;
  label: string | React.ReactElement;
  options: Options<GenericOption>;
  className?: any;
  onChange?: (
    selectedOptionOrOptions: OnChangeValue<GenericOption | GenericOption[] | null, boolean>,
    formikSetFieldValue: (field: string, value: any) => void,
  ) => void;
  isMulti?: boolean;
}

function FormikSelect(props: FormikSelectProps) {
  const {
    name,
    label,
    className,
    options,
    onChange = (selectedOptionOrOptions: OnChangeValue<GenericOption | GenericOption[] | null, boolean>) => {},
    isMulti = false,
  } = props;

  return (
    <div className={className}>
      {/* This is a formik component so formik does validation and manages state */}
      <Field name={name}>
        {(fieldProps: FieldProps) => {
          const { field, form } = fieldProps;
          const handleChange = (option: OnChangeValue<GenericOption | GenericOption[] | null, boolean>) => {
            onChange(option, form.setFieldValue);

            let value;
            if (option === undefined || option === null) {
              // Purposely send null so the backend clears the value (django PATCH ignores undefineds by default)
              value = null;
            } else if (isMulti) {
              value = (option as GenericOption[]).map((item: GenericOption) => item.value);
            } else {
              value = (option as GenericOption).value;
            }
            form.setFieldValue(field.name, value);
          };
          const doesOptionMatchField = (option: GenericOption) => {
            if (option.value && option.value.id && field.value && field.value.id) {
              // Assuming we are comparing model objects, just need same id to match
              return option.value.id === field.value.id;
            } else {
              return option.value === field.value;
            }
          };
          const getValue = () => {
            if (options) {
              return isMulti
                ? options.filter((option: GenericOption) => field.value.indexOf(option.value) >= 0)
                : options.find(doesOptionMatchField);
            } else {
              console.warn('No options passed to FormikSelect with label: ' + label);
              return isMulti ? [] : ('' as any);
            }
          };
          return (
            <FormGroup>
              {label && (
                // This is a react-bootstrap component match label look-and-feel
                <FormLabel>{label}</FormLabel>
              )}
              {/* This is a react-select component to make type-ahead work */}
              <Select
                name={field.name}
                value={getValue()}
                onChange={handleChange}
                options={options}
                isMulti={isMulti}
                isClearable={true}
              />
              {/* Not getting errors messages?
                Try adding 'fieldName: undefined,' to initialStateOfEditableScheduleFields
              */}
              <ErrorMessage name={name}>
                {(errorMessage) => {
                  console.log('in error message for a select', props.name);
                  return <CustomErrorMessage errorMessage={errorMessage} />;
                }}
              </ErrorMessage>
            </FormGroup>
          );
        }}
      </Field>
    </div>
  );
}

export default FormikSelect;
export type { GenericOption, IdOption };
