import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import TeamType from '../../domain/team';
import SmrResponsibilityGrid from '../../components/smr/SmrResponsibilityGrid/SmrResponsibilityGrid';

type SmrResponsibilitiesProps = {
  selectedTeam: TeamType;
};
export const SmrResponsibilities = (props: SmrResponsibilitiesProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const newTeamMemberId = searchParams.get('newTeamMember');
  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">SMR Responsibilities</h4>
        </Col>
      </Row>
      {newTeamMemberId && (
        <Alert variant="info">Add SMR Responsibilities for new team member, hit next to continue</Alert>
      )}
      <Row>
        <SmrResponsibilityGrid selectedTeam={props.selectedTeam} />
      </Row>
      <Row>
        {newTeamMemberId && (
          <Link to={'/country-assignments?newTeamMember=' + newTeamMemberId}>
            <Button>Next</Button>
          </Link>
        )}
      </Row>
    </Container>
  );
};

export type { SmrResponsibilitiesProps };
