import { RowClassParams, RowDoubleClickedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import PermissionType from '../../../../domain/permission';
import { doActionsContain, hasPermission } from '../../../../helpers/PermissionHelper';
import { RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL, RECURRING_GOAL_SCHEDULES_URL } from '../../../../constants/urls';
import {
  defaultOnGridReady,
  getAssignedToString,
  getDefaultColDef,
  getDefaultStatusBar,
  getDefaultUseQueryConfig,
  useGridComponentStates,
} from '../../../../helpers/GridComponentHelpers';
import GenericDeleteDialog from '../../../GenericDeleteDialog/GenericDeleteDialog';
import RecurringGoalScheduleType, { convertToTypeToDisplay } from '../../../../domain/recurringGoalSchedule';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../FeedbackMessages/FeedbackMessages';
import GenericGridActions from '../../../GenericGridActions/GenericGridActions';
import ModifyRecurringGoalScheduleDialog, {
  defaultHandleModifyRecurringGoalScheduleDialogOk,
} from '../ModifyRecurringGoalScheduleDialog/ModifyRecurringGoalScheduleDialog';
import TeamType from '../../../../domain/team';
import FieldCoreProcessType from '../../../../domain/fieldCoreProcess';
import TeamMemberType from '../../../../domain/teamMember';
import { MemberSelect } from '../../../members/MemberSelect/MemberSelect';
import { compareDatesForAgGridFilter, formatDateForAgGrid } from '../../../../helpers/AgGridDateHelpers';
import styles from './RecurringGoalScheduleGrid.module.scss';
import GenericRequestChangeDialog, {
  genericHandleRequestChangeOk,
} from '../../../GenericRequestChangeDialog/GenericRequestChangeDialog';
import RequestedChangeToSend from '../../../GenericRequestChangeDialog/requestedChange';
import { makeInQueryFromList } from '../../../../helpers/ObjectIdHelpers';
import GoalGridSummary from '../../GoalGridSummary/GoalGridSummary';
import { replaceAdaptedGoalFieldsWithCore } from '../../../apis/recurring_goal_schedule_api';
import FcpNameWithAlertIcons from '../../../FcpNameWithAlertIcons/FcpNameWithAlertIcons';

type RecurringGoalScheduleGridProps = {
  selectedTeam: TeamType;
  fieldCoreProcesses?: FieldCoreProcessType[];
  memberQuickSelect?: boolean;
  hideCreateButton?: boolean;
  idToFocusOn?: number;
  filteredCallsign?: string;
};

const getColumns = (
  openModifyDialog: (objectToModify: RecurringGoalScheduleType) => void,
  openRequestChangeDialog: (objectToModify: RecurringGoalScheduleType) => void,
  team: TeamType,
  memberQuickSelect: boolean | undefined,
  filteredCallsign: string | undefined,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) => {
  let assignedToColumn;
  if (memberQuickSelect) {
    assignedToColumn = {
      headerName: 'Assigned To',
      width: 200,
      field: 'assignedToMember',
      filterValueGetter: (params: ValueGetterParams) => {
        const schedule = params.data as RecurringGoalScheduleType;
        const assignedTo = schedule?.assignedToMember;
        return getAssignedToString(assignedTo);
      },
      cellRenderer: 'assignedToQuickSelectRenderer',
      cellRendererParams: {
        selectedTeam: team,
        onChange: (newTeamMember: TeamMemberType | undefined, oldRecurringGoalSchedule: RecurringGoalScheduleType) => {
          // I would love to use this, but grid API isn't available at the start
          // if(gridApi) {
          //   gridApi.showLoadingOverlay()
          // }
          const modifiedScheduleToSend = convertToTypeToDisplay(oldRecurringGoalSchedule);
          modifiedScheduleToSend.assignedToMember = newTeamMember?.id;
          // We could also wrap this in react-query's useMutation, but that seems overkill
          const response = axios
            .patch(RECURRING_GOAL_SCHEDULES_URL + '/' + oldRecurringGoalSchedule.id + '/', modifiedScheduleToSend)
            .then(() => {
              // TODO handle errors
              addFeedbackMessage({
                key: createFeedbackMessageKey('recurringTaskSchedule', 'changeTeamMember', oldRecurringGoalSchedule.id),
                status: 'success',
                messageBody: <span>Team member changed successfully.</span>,
              });
              refetch();
            });
          return response;
        },
      },
      cellStyle: { overflow: 'visible' },
    };
  } else {
    assignedToColumn = {
      headerName: 'Assigned To',
      width: 200,
      // field: 'templateTaskToCreate.assignedToMember.callSign',
      valueGetter: (params: ValueGetterParams) => {
        const schedule = params.data as RecurringGoalScheduleType;
        const assignedTo = schedule.assignedToMember;
        return getAssignedToString(assignedTo);
      },
    };
  }
  return [
    {
      field: 'summaryTemplate',
      headerName: 'Summary',
      tooltipField: 'descriptionTemplate',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
      cellRenderer: 'summaryRenderer',
    },
    {
      field: 'fieldCoreProcess.name',
      headerName: 'FCP',
      width: 90,
      cellRenderer: FcpNameWithAlertIcons
    },
    assignedToColumn,
    {
      field: 'frequency',
      headerName: 'Freq.',
      width: 90,
    },
    {
      field: 'startDateTime',
      headerName: 'Start',
      width: 110,
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return '';
        }
        return formatDateForAgGrid(params.value, team.timezone);
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return compareDatesForAgGridFilter(filterLocalDateAtMidnight, cellValue, team.timezone);
        },
      },
    },
    {
      field: 'totalPoints',
      headerName: 'Weight',
      width: 90,
    },
    {
      headerName: 'Actions',
      width: 90,
      cellRenderer: 'actionsRenderer',
      cellRendererParams: {
        onEditClick: (scheduleToEdit: RecurringGoalScheduleType) => {
          openModifyDialog(scheduleToEdit);
        },
        onRequestChangeClick: (scheduleToRequestChange: RecurringGoalScheduleType) => {
          openRequestChangeDialog(scheduleToRequestChange);
        },
      },
    },
  ];
};

const RecurringGoalScheduleGrid = (props: RecurringGoalScheduleGridProps) => {
  let gridComponentStates = useGridComponentStates<RecurringGoalScheduleType>();
  const { filteredCallsign } = props;
  const recurringGoalSchedulesRequestConfig = {
    params: {
      team: props.selectedTeam.id,
      // __in requires extra implementation by backend, check before copy/pasting!
      created_by_core__field_core_process__in: makeInQueryFromList(props.fieldCoreProcesses),
      assigned_to_member__call_sign: filteredCallsign,
    },
  } as AxiosRequestConfig;

  const {
    isRefetching: isRefetchingSchedules,
    error: schedulesError,
    data: schedulesData,
    refetch: refetchSchedules,
  } = useQuery(
    ['getRecurringGoalSchedulesForTeam', JSON.stringify(recurringGoalSchedulesRequestConfig)],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridComponentStates.gridApi) {
        gridComponentStates.gridApi.showLoadingOverlay();
      }
      return axios.get<RecurringGoalScheduleType[]>(RECURRING_GOAL_SCHEDULES_URL, recurringGoalSchedulesRequestConfig);
    },
    getDefaultUseQueryConfig<RecurringGoalScheduleType>(
      gridComponentStates.gridApi,
      gridComponentStates.openModifyDialog,
      props.idToFocusOn,
      5000
    ),
  );

  // useState no longer needed because of AG-6524
  // (thanks for listening to my feedback Ag-grid team!)
  const columnDefs = getColumns(
    gridComponentStates.openModifyDialog,
    gridComponentStates.openRequestChangeDialog,
    props.selectedTeam,
    props.memberQuickSelect,
    props.filteredCallsign,
    refetchSchedules,
    gridComponentStates.addFeedbackMessage,
  );

  const getRecurringGoalSchedulePermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const {
    error: schedulePermissionsError,
    data: schedulePermissionsData,
    isLoading: isPermissionsLoading,
  } = useQuery(['getRecurringGoalSchedulePermissions', getRecurringGoalSchedulePermissionsRequestConfig], () => {
    return axios.get<PermissionType[]>(
      RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL,
      getRecurringGoalSchedulePermissionsRequestConfig,
    );
  });

  if (schedulesError || schedulePermissionsError) return <p>Error!</p>;

  const recurringGoalSchedules = schedulesData?.data;
  const recurringGoalSchedulePermissions = schedulePermissionsData?.data;

  if (!isPermissionsLoading && (!recurringGoalSchedulePermissions || recurringGoalSchedulePermissions.length === 0)) {
    return <p>No permission for recurring goal schedules.</p>;
  }

  const handleModifyDialogDeleteIcon = (updatedSchedule: RecurringGoalScheduleType) => {
    gridComponentStates.closeModifyDialog();
    gridComponentStates.openDeleteDialog(updatedSchedule);
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    const schedule = event.data as RecurringGoalScheduleType;
    if (doActionsContain(schedule, 'change')) {
      gridComponentStates.openModifyDialog(schedule);
    }
  };

  const onCreateClick = () => {
    // Null for new object
    gridComponentStates.openModifyDialog(null);
  };

  return (
    <Container
      fluid
      className={
        //styles.container +
        ' d-flex flex-column flex-grow-1'
      }
      style={{ height: '100%' }}
    >
      <Row className="d-flex flex-column flex-grow-1">
        <Col>
          <div
            className="ag-theme-balham"
            style={{
              height: '100%',
              width: '100%',
              minHeight: '200px',
            }}
          >
            <AgGridReact
              onGridReady={(params) => {
                defaultOnGridReady(
                  params,
                  recurringGoalSchedules,
                  isRefetchingSchedules,
                  gridComponentStates.setGridApi,
                );
              }}
              frameworkComponents={{
                actionsRenderer: GenericGridActions,
                // tagsRenderer: TaskGridTags,
                // dateAndCreationIconRenderer: TaskGridDateAndCreationIcon,
                summaryRenderer: GoalGridSummary,
                assignedToQuickSelectRenderer: getMultipleAssignedToMemberSelect,
              }}
              getRowId={(params) => params.data?.id?.toString()}
              onRowDoubleClicked={handleRowDoubleClick}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              tooltipShowDelay={450}
              defaultColDef={getDefaultColDef()}
              getRowClass={(params: RowClassParams) => {
                const goalSchedule = params.data as RecurringGoalScheduleType;
                const rowStyles = [];
                if (!goalSchedule.assignedToMember || !goalSchedule.assignedToMember.account) {
                  rowStyles.push(styles.rowWarning);
                }
                return rowStyles;
              }}
              // Normally we pass in row data via api on react-query onSuccess instead.
              // Otherwise this comes through as undefined on user tab switching which breaks loading spinner
              // However, apparently onSuccess doesn't get called when the data was cached
              // That means when you flip Legal to Station Expansion and back to Legal, Legal Cases don't show up
              // Using rowData fixes this, but I'm hoping for a better solution later.
              rowData={recurringGoalSchedules}
              statusBar={getDefaultStatusBar()}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {recurringGoalSchedulePermissions &&
            hasPermission(recurringGoalSchedulePermissions, 'add_recurringgoalschedule') &&
            !props.hideCreateButton && (
              <Button variant="primary" onClick={onCreateClick}>
                Create a goal <i className="bi bi-plus" />
              </Button>
            )}
        </Col>
      </Row>
      <GenericDeleteDialog
        state={gridComponentStates.deleteDialogState}
        onCancel={gridComponentStates.closeDeleteDialog}
        onOk={(idToDelete: number) =>
          handleDeleteRecurringGoalScheduleDialogOk(
            idToDelete,
            gridComponentStates.closeDeleteDialog,
            gridComponentStates.disableSubmissionDeleteDialog,
            refetchSchedules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        objectTypeString="recurring goal schedule"
      />
      <GenericRequestChangeDialog
        state={gridComponentStates.requestChangeDialogState}
        onOk={(requestedChange: RequestedChangeToSend) =>
          genericHandleRequestChangeOk(
            requestedChange,
            gridComponentStates.closeRequestChangeDialog,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onCancel={gridComponentStates.closeRequestChangeDialog}
        objectTypeString={'recurring goal schedule'}
      />
      <ModifyRecurringGoalScheduleDialog
        selectedTeam={props.selectedTeam}
        onReplaceAdaptedFields={(scheduleToReplace) => {
          return replaceAdaptedGoalFieldsWithCore(scheduleToReplace).then(() => {
            console.log('refetching');
            refetchSchedules();
          })
        }}
        // Should I change how I pass this state somehow?
        state={gridComponentStates.modifyDialogState}
        onCancel={gridComponentStates.closeModifyDialog}
        onOk={(modifySchedule, idToUpdate) =>
          defaultHandleModifyRecurringGoalScheduleDialogOk(
            modifySchedule,
            idToUpdate,
            gridComponentStates.closeModifyDialog,
            refetchSchedules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onDeleteIcon={handleModifyDialogDeleteIcon}
      />
    </Container>
  );
};

function getMultipleAssignedToMemberSelect(props: any) {
  const schedule: RecurringGoalScheduleType = props.data;
  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <MemberSelect
          value={props.value}
          data={props.data}
          selectedTeam={props.selectedTeam}
          onChange={props.onChange}
        />
      </div>
      {schedule.secondaryAssignedToMembers && schedule.secondaryAssignedToMembers.length > 0 && (
        <span className={styles.secondaryAssignedToCount}>+{schedule.secondaryAssignedToMembers.length}</span>
      )}
    </div>
  );
}

// TODO should I make this generic, too?
function handleDeleteRecurringGoalScheduleDialogOk(
  idToDelete: number,
  closeDeleteDialog: () => void,
  disableSubmissionDeleteDialog: () => void,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  disableSubmissionDeleteDialog();
  return axios.delete(RECURRING_GOAL_SCHEDULES_URL + '/' + idToDelete + '/').then((response) => {
    closeDeleteDialog();
    addFeedbackMessage({
      key: createFeedbackMessageKey('recurringGoalSchedule', 'delete', idToDelete),
      status: 'success',
      messageBody: <span>Recurring goal schedule deleted successfully.</span>,
    });
    refetch();
  });
}

export default RecurringGoalScheduleGrid;
