import '../../../../styles.css';
import FieldCoreProcessType, { FieldCoreProcessTypeToSend } from '../../../../domain/fieldCoreProcess';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { Form, Formik, FormikProps } from 'formik';
import FormikController from '../../../../formik/FormikSelect/FormikController';
import * as Yup from 'yup';
import { createFcpOptions, useGetFcpsByDepartment } from '../../apis/fcp_api';
import { useGetFcpCategories } from '../../apis/categories_api';
import { getMode, getSimpleModal, ModifyDialogState } from '../../../../helpers/GridComponentHelpers';
import { GenericOption } from '../../../../formik/FormikSelect/FormikSelect';
import { useGetLeadPeople } from '../../apis/accounts_api';
import { getAccountName } from '../../../../helpers/AccountHelpers';
import { FCP_TRIGGER_TYPES } from '../../../../helpers/FieldCoreProcessHelpers';
import { useGetImpactMetrics } from '../../apis/impact_metric_api';
import React from 'react';
import { Mode } from '../../../../state_types/mode';
import _ from 'lodash';
import { FcpSubstepTypePost } from '../../../../domain/fcpSubstep';

type ModifyFieldCoreProcessDialogProps = {
  state: ModifyDialogState<FieldCoreProcessType>;
  // onOk: (updatedFcpFields: FieldCoreProcessTypeToSend, idToUpdate: number | null) => void;
  onOk: (updatedFcpFields: FieldCoreProcessTypeToSend, idToUpdate?: number | null) => Promise<any>;
  onCancel: () => void;
  // onDeleteIcon: (fcpToDelete: FieldCoreProcessType) => void;

  department: string;
  canEditFcps: boolean;

  // fcps: FieldCoreProcessType[];
  // ims: GoalSourceFieldType[];
  // categories: string[];
  // possibleAssignees: AccountType[];
};

const FcpFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().optional(),
  category: Yup.string().required('Required'),
  lead: Yup.number().nullable(),
  implementationManager: Yup.number().nullable(),
  trigger: Yup.object()
    .shape({
      type: Yup.string().nullable(),
      prereqFcp: Yup.number().when('type', {
        is: FCP_TRIGGER_TYPES.FCP_INST,
        then: Yup.number().required('Required'),
        otherwise: Yup.number().nullable().optional(),
      }),
      impactMetric: Yup.number().when('type', {
        is: FCP_TRIGGER_TYPES.IM,
        then: Yup.number().required('Required'),
        otherwise: Yup.number().nullable().optional(),
      }),
      impactMetricThreshold: Yup.number().when('type', {
        is: FCP_TRIGGER_TYPES.IM,
        then: Yup.number().required('Required'),
        otherwise: Yup.number().nullable().optional(),
      }),
    })
    .required('Required'),
  isPaused: Yup.boolean().required('Required'),
  substeps: Yup.array()
    .of(
      Yup.object().shape({
        // TODO!!!
        // assignedToMember: Yup.number().nullable().required('Required'),
        // totalPoints: Yup.number().required('Required'),
      }),
    )
    .optional(),
});

function ModifyFieldCoreProcessDialog(props: ModifyFieldCoreProcessDialogProps) {
  const mode = getMode(props.state);

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    error: categoriesError,
  } = useGetFcpCategories(props.state.isOpen);
  const {
    data: leadPeople,
    isLoading: isLeadPeopleLoading,
    error: leadPeopleError,
  } = useGetLeadPeople(props.state.isOpen);
  const {
    data: fcps,
    isLoading: isFcpsLoading,
    error: fcpError,
  } = useGetFcpsByDepartment(props.department, props.state.isOpen);
  const { data: ims, isLoading: isImsLoading, error: imError } = useGetImpactMetrics(props.state.isOpen);

  let title: string;
  if (mode === Mode.Add) {
    title = 'Create a new FCP for ' + props.department;
  } else {
    title = 'Modify FCP for ' + props.department;
  }

  if (isCategoriesLoading || isLeadPeopleLoading || isFcpsLoading || isImsLoading) {
    return getSimpleModal(props.state.isOpen, props.onCancel, title, 'Loading...');
  }
  if (categoriesError || leadPeopleError || fcpError || imError) {
    return getSimpleModal(props.state.isOpen, props.onCancel, title, 'Error!');
  }
  if (categories === undefined || leadPeople === undefined || fcps === undefined || ims === undefined) {
    return getSimpleModal(props.state.isOpen, props.onCancel, title, 'No Data');
  }

  let initialStateOfEditableEventFields;
  let fcpId: number | null;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const fcp = props.state.objectToModify!;
    const fcpTriggerInfo = fcp.triggerInfo;
    const substeps = _.cloneDeep(fcp.substeps);

    initialStateOfEditableEventFields = {
      name: fcp.name,
      description: fcp.description,
      department: props.department,
      category: fcp.category,
      lead: fcp.lead?.id,
      implementationManager: fcp.implementationManager?.id,
      trigger: {
        type: fcpTriggerInfo?.type,
        prereqFcp: fcpTriggerInfo?.prereqFcp,
        impactMetric: fcpTriggerInfo?.impactMetric,
        impactMetricThreshold: fcpTriggerInfo?.impactMetricThreshold,
      },
      isPaused: fcp.isPaused,
      substeps: substeps,
    } as FieldCoreProcessTypeToSend;
    fcpId = fcp.id;
  } else {
    initialStateOfEditableEventFields = {
      name: '',
      description: '',
      department: props.department,
      category: '', //props.categories[0],
      lead: undefined,
      implementationManager: undefined,
      trigger: {
        type: undefined,
        prereqFcp: undefined,
        impactMetric: undefined,
        impactMetricThreshold: undefined,
      },
      isPaused: false,
      substeps: [],
    } as FieldCoreProcessTypeToSend;
    fcpId = null;
  }

  const categoryOptions = categories.map((category) => {
    return {
      label: category.name,
      value: category.name,
    } as GenericOption;
  });
  const leadPeopleOptions = leadPeople.map((leadPerson) => {
    return {
      label: getAccountName(leadPerson),
      value: leadPerson.id,
    };
  });
  const fcpTriggerTypeOptions = Object.values(FCP_TRIGGER_TYPES).map((valueString) => {
    return {
      label: valueString,
      value: valueString,
    };
  });
  const fcpOptions = createFcpOptions(fcps);
  const impactMetricOptions = ims.map((impactMetric) => {
    return {
      label: impactMetric.name,
      value: impactMetric.id,
    };
  });

  const onSubmit = (modifiedFcp: FieldCoreProcessTypeToSend, { setSubmitting }: any) => {
    setTimeout(() => {
      if (modifiedFcp === null) {
        // If this ever happens, we probably need to use 'useEffect' in some capacity
        alert('new event was null, contact developers');
        setSubmitting(false);
        return;
      }
      props.onOk(modifiedFcp, fcpId).then(() => {
        setSubmitting(false);
      });
    }, 400);
  };
  const isOpen = props.state.isOpen;

  // This component causes 'findDOMNode is deprecated in StrictMode' warning
  // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
  // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
  // TODO fix this warning
  return (
    <Modal show={isOpen} onHide={props.onCancel}>
      <Formik initialValues={initialStateOfEditableEventFields} validationSchema={FcpFormSchema} onSubmit={onSubmit}>
        {(formikProps: FormikProps<FieldCoreProcessTypeToSend>) => {
          const onChooseTriggerType = () => {
            formikProps.setFieldValue('trigger.prereqFcp', undefined);
            formikProps.setFieldValue('trigger.impactMetric', undefined);
            formikProps.setFieldValue('trigger.impactMetricThreshold', undefined);
          };
          let onAddSubstep = () => {
            const substepWithMaxOrder = _.maxBy(
              formikProps.values.substeps,
              (substep: FcpSubstepTypePost) => substep.order,
            );
            let maxOrder;
            if (substepWithMaxOrder === undefined) {
              maxOrder = 0;
            } else {
              maxOrder = substepWithMaxOrder.order;
            }
            // formikProps.values.substeps.push({ order: formikProps.values.substeps.length });
            formikProps.setFieldValue(`substeps[${formikProps.values.substeps.length}]`, {
              order: maxOrder + 1,
              name: '',
            });
          };
          const moveSubstepUp = (index: number) => {
            let substeps = formikProps.values.substeps;
            let aboveIndex = index - 1;
            const substepToMoveUp = substeps[index];
            const substepAbove = substeps[aboveIndex];
            const substepToMoveUpOrder = substepToMoveUp.order;
            const substepAboveOrder = substepAbove.order;
            substepAbove.order = substepToMoveUpOrder;
            substepToMoveUp.order = substepAboveOrder;
            formikProps.setFieldValue('substeps', substeps);
          };
          const moveSubstepDown = (index: number) => {
            let substeps = formikProps.values.substeps;
            let belowIndex = index + 1;
            const substepToMoveDown = substeps[index];
            const substepBelow = substeps[belowIndex];
            const substepToMoveDownOrder = substepToMoveDown.order;
            const substepBelowOrder = substepBelow.order;
            substepBelow.order = substepToMoveDownOrder;
            substepToMoveDown.order = substepBelowOrder;
            formikProps.setFieldValue('substeps', substeps);
          };
          const deleteSubstep = (index: number) => {
            let substeps = formikProps.values.substeps;
            substeps.splice(index, 1);
            // Fix order numbering
            substeps.forEach((substep, index) => {
              substep.order = index + 1;
            });
            formikProps.setFieldValue('substeps', substeps);
          };
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormikController control="select" name="category" label="Category" options={categoryOptions} />
                <FormikController control="input" name="name" label="Name" />
                <FormikController control="input" name="description" label="Description (optional)" />
                {mode === Mode.Change && <FormikController control="checkbox" name="isPaused" label="Is Paused?" />}
                <FormikController control="select" name="lead" label="Lead" options={leadPeopleOptions} />
                <FormikController
                  control="select"
                  name="implementationManager"
                  label="Implementation Manager"
                  options={leadPeopleOptions}
                />
                <FormikController
                  control="select"
                  name="trigger.type"
                  label="Trigger Type"
                  options={fcpTriggerTypeOptions}
                  onChange={onChooseTriggerType}
                />

                {formikProps.values.trigger?.type && (
                  <div className={'pl-5'}>
                    {formikProps.values.trigger?.type === FCP_TRIGGER_TYPES.FCP_INST && (
                      <FormikController
                        control="select"
                        name="trigger.prereqFcp"
                        label="Prerequisite FCP"
                        options={fcpOptions}
                      />
                    )}
                    {formikProps.values.trigger?.type === FCP_TRIGGER_TYPES.IM && (
                      <>
                        <FormikController
                          control="select"
                          name="trigger.impactMetric"
                          label="Impact Metric"
                          options={impactMetricOptions}
                        />
                        <FormikController
                          control="input"
                          name="trigger.impactMetricThreshold"
                          label="Impact Metric Threshold"
                        />
                      </>
                    )}
                    <FormikController
                      control="input"
                      name="trigger.numDaysBeforeDue"
                      label="Due how many days after trigger?"
                    />
                  </div>
                )}
                <br />
                <h3>Substeps</h3>
                <Alert variant="warning">
                  Be careful when deleting substeps, this deletes substep installation history as well! Rename when
                  possible!
                </Alert>
                {formikProps.values.substeps
                  .sort((a, b) => a.order - b.order)
                  .map((substep, index) => {
                    return (
                      <Row key={substep.order}>
                        <Col xs={1}>{substep.order}.</Col>
                        <Col>
                          <FormikController className={'p-0'} control="input" name={`substeps[${index}].name`} />
                        </Col>
                        <Col xs={2}>
                          {index !== 0 && (
                            <Button variant="light" onClick={() => moveSubstepUp(index)}>
                              <i className="bi bi-arrow-up" />
                            </Button>
                          )}
                        </Col>
                        <Col xs={2}>
                          {index !== formikProps.values.substeps.length - 1 && (
                            <Button variant="light" onClick={() => moveSubstepDown(index)}>
                              <i className="bi bi-arrow-down" />
                            </Button>
                          )}
                        </Col>
                        <Col xs={2}>
                          <Button variant="danger" onClick={() => deleteSubstep(index)}>
                            <i className="bi bi-trash" />
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                <Row>
                  <Button className="ml-5" variant="secondary" onClick={onAddSubstep}>
                    Add
                  </Button>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={props.onCancel}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>
                  Ok
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ModifyFieldCoreProcessDialog;
