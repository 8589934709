import { RowClassParams, RowDoubleClickedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import TeamType from '../../../../domain/team';
import RecurringTaskScheduleType, { convertToTypeToSend } from '../../../../domain/recurringTaskSchedule';
import ModifyRecurringTaskScheduleDialog, {
  defaultHandleModifyRecurringTaskScheduleDialogOk,
} from '../ModifyRecurringTaskScheduleDialog/ModifyRecurringTaskScheduleDialog';
import TaskGridSummary from '../../TaskGridSummary/TaskGridSummary';
import TaskGridTags from '../../TaskGridTags/TaskGridTags';
import { compareDatesForAgGridFilter, formatDateForAgGrid } from '../../../../helpers/AgGridDateHelpers';
import { doActionsContain, hasPermission } from '../../../../helpers/PermissionHelper';
import PermissionType from '../../../../domain/permission';
import { RECURRING_TASK_SCHEDULES_URL, RECURRING_TASK_SCHEDULE_PERMISSIONS_URL } from '../../../../constants/urls';
import {
  defaultOnGridReady,
  getAssignedToString,
  getDefaultColDef,
  getDefaultStatusBar,
  getDefaultUseQueryConfig,
  useGridComponentStates,
} from '../../../../helpers/GridComponentHelpers';
import FieldCoreProcessType from '../../../../domain/fieldCoreProcess';
import styles from './RecurringTaskSchedulesGrid.module.scss';
import TriggerRuleType from '../../../../domain/triggerRule';
import { MemberSelect } from '../../../members/MemberSelect/MemberSelect';
import TeamMemberType from '../../../../domain/teamMember';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../FeedbackMessages/FeedbackMessages';
import GenericDeleteDialog from '../../../GenericDeleteDialog/GenericDeleteDialog';
import GenericGridActions from '../../../GenericGridActions/GenericGridActions';
import GenericRequestChangeDialog, {
  genericHandleRequestChangeOk,
} from '../../../GenericRequestChangeDialog/GenericRequestChangeDialog';
import RequestedChangeToSend from '../../../GenericRequestChangeDialog/requestedChange';
import { makeInQueryFromList } from '../../../../helpers/ObjectIdHelpers';
import TagType from '../../../../domain/tag';
import FcpNameWithAlertIcons from '../../../FcpNameWithAlertIcons/FcpNameWithAlertIcons';
import { replaceAdaptedRecurringFieldsWithCore } from '../../../apis/recurring_task_schedule_api';

type RecurringTaskSchedulesGridProps = {
  selectedTeam: TeamType;
  fieldCoreProcesses?: FieldCoreProcessType[];
  memberQuickSelect?: boolean;
  hideCreateButton?: boolean;
  filteredCallsign?: string;
  idToFocusOn?: number;
};

const getRecurringTaskSchedulesColumns = (
  openModifyDialog: (objectToModify: RecurringTaskScheduleType) => void,
  openRequestChangeDialog: (objectToModify: RecurringTaskScheduleType) => void,
  team: TeamType,
  memberQuickSelect: boolean | undefined,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) => {
  let assignedToColumn;
  if (memberQuickSelect) {
    assignedToColumn = {
      headerName: 'Assigned To',
      width: 170,
      field: 'templateTaskToCreate.assignedToMember',
      filterValueGetter: (params: ValueGetterParams) => {
        const schedule = params.data as RecurringTaskScheduleType;
        const assignedTo = schedule?.templateTaskToCreate?.assignedToMember;
        return getAssignedToString(assignedTo);
      },
      cellRenderer: 'assignedToQuickSelectRenderer',
      cellRendererParams: {
        selectedTeam: team,
        onChange: (newTeamMember: TeamMemberType | undefined, oldRecurringTaskSchedule: RecurringTaskScheduleType) => {
          // I would love to use this, but grid API isn't available at the start
          // if(gridApi) {
          //   gridApi.showLoadingOverlay()
          // }
          const modifiedScheduleToSend = convertToTypeToSend(oldRecurringTaskSchedule);
          modifiedScheduleToSend.templateTaskToCreate.assignedToMember = newTeamMember?.id;
          // We could also wrap this in react-query's useMutation, but that seems overkill
          const response = axios
            .patch(RECURRING_TASK_SCHEDULES_URL + '/' + oldRecurringTaskSchedule.id + '/', modifiedScheduleToSend)
            .then(() => {
              // TODO handle errors
              addFeedbackMessage({
                key: createFeedbackMessageKey('recurringTaskSchedule', 'changeTeamMember', oldRecurringTaskSchedule.id),
                status: 'success',
                messageBody: <span>Team member changed successfully.</span>,
              });
              refetch();
            });
          return response;
        },
      },
      cellStyle: { overflow: 'visible' },
    };
  } else {
    assignedToColumn = {
      headerName: 'Assigned To',
      width: 120,
      // field: 'templateTaskToCreate.assignedToMember.callSign',
      valueGetter: (params: ValueGetterParams) => {
        const schedule = params.data as TriggerRuleType;
        const assignedTo = schedule.templateTaskToCreate.assignedToMember;
        return getAssignedToString(assignedTo);
      },
    };
  }
  return [
    {
      field: 'templateTaskToCreate.summaryTemplate',
      headerName: 'Summary',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
      cellRenderer: 'summaryRenderer',
    },
    {
      field: 'fieldCoreProcess.name',
      headerName: 'FCP',
      width: 90,
      cellRenderer: FcpNameWithAlertIcons
    },
    assignedToColumn,
    {
      field: 'templateTaskToCreate.tags',
      headerName: 'Tags',
      cellRenderer: 'tagsRenderer',
      width: 130,
      filterParams: {
        valueGetter: (params: ValueGetterParams) => {
          return params.data.templateTaskToCreate?.tags
            .map((tag: TagType) => {
              return tag.name;
            })
            .join(', ');
        },
      },
    },
    {
      field: 'frequency',
      headerName: 'Freq.',
      width: 70,
    },
    {
      field: 'nextDueDateTime',
      headerName: 'Next Due',
      width: 90,
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return '';
        }
        return formatDateForAgGrid(params.value, team.timezone);
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return compareDatesForAgGridFilter(filterLocalDateAtMidnight, cellValue, team.timezone);
        },
      },
    },
    {
      field: 'templateTaskToCreate.totalPoints',
      headerName: 'Wt.',
      width: 60,
    },
    {
      headerName: 'Actions',
      width: 90,
      cellRenderer: 'actionsRenderer',
      cellRendererParams: {
        onEditClick: (scheduleToEdit: RecurringTaskScheduleType) => {
          openModifyDialog(scheduleToEdit);
        },
        onRequestChangeClick: (scheduleToRequestChange: RecurringTaskScheduleType) => {
          openRequestChangeDialog(scheduleToRequestChange);
        },
      },
    },
  ];
};

const RecurringTaskSchedulesGrid = (props: RecurringTaskSchedulesGridProps) => {
  let gridComponentStates = useGridComponentStates<RecurringTaskScheduleType>();
  const { filteredCallsign } = props;
  const recurringTaskSchedulesRequestConfig = {
    params: {
      team: props.selectedTeam.id,
      // __in requires extra implementation by backend, check before copy/pasting!
      created_by_core__field_core_process__in: makeInQueryFromList(props.fieldCoreProcesses),
      template_task_to_create__assigned_to_member__call_sign: filteredCallsign,
    },
  } as AxiosRequestConfig;
  const {
    isRefetching: isRefetchingSchedules,
    error: schedulesError,
    data: schedulesData,
    refetch: refetchSchedules,
  } = useQuery(
    ['getRecurringTaskSchedulesForTeam', JSON.stringify(recurringTaskSchedulesRequestConfig)],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridComponentStates.gridApi) {
        gridComponentStates.gridApi.showLoadingOverlay();
      }
      return axios.get<RecurringTaskScheduleType[]>(RECURRING_TASK_SCHEDULES_URL, recurringTaskSchedulesRequestConfig);
    },
    getDefaultUseQueryConfig<RecurringTaskScheduleType>(
      gridComponentStates.gridApi,
      gridComponentStates.openModifyDialog,
      props.idToFocusOn,
      5000
    ),
  );

  // useState no longer needed because of AG-6524
  // (thanks for listening to my feedback Ag-grid team!)
  const columnDefs = getRecurringTaskSchedulesColumns(
    gridComponentStates.openModifyDialog,
    gridComponentStates.openRequestChangeDialog,
    props.selectedTeam,
    props.memberQuickSelect,
    refetchSchedules,
    gridComponentStates.addFeedbackMessage,
  );

  const recurringTaskSchedulePermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const {
    error: schedulePermissionsError,
    data: schedulePermissionsData,
    isLoading: isPermissionsLoading,
  } = useQuery(['getRecurringTaskSchedulePermissions', recurringTaskSchedulePermissionsRequestConfig], () => {
    return axios.get<PermissionType[]>(
      RECURRING_TASK_SCHEDULE_PERMISSIONS_URL,
      recurringTaskSchedulePermissionsRequestConfig,
    );
  });

  if (schedulesError || schedulePermissionsError) return <p>Error!</p>;

  const recurringTaskSchedules = schedulesData?.data;
  const schedulePermissions = schedulePermissionsData?.data;

  if (!isPermissionsLoading && (!schedulePermissions || schedulePermissions.length === 0)) {
    return <p>No permission for recurring goal schedules.</p>;
  }

  const handleModifyDialogDeleteIcon = (updatedSchedule: RecurringTaskScheduleType) => {
    gridComponentStates.closeModifyDialog();
    gridComponentStates.openDeleteDialog(updatedSchedule);
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    const schedule = event.data as RecurringTaskScheduleType;
    if (doActionsContain(schedule, 'change')) {
      gridComponentStates.openModifyDialog(schedule);
    }
  };

  const onCreateClick = () => {
    // Null for new object
    gridComponentStates.openModifyDialog(null);
  };

  return (
    <Container fluid className={' d-flex flex-column flex-grow-1'} style={{ height: '100%' }}>
      <Row className="d-flex flex-column flex-grow-1">
        <Col>
          <div
            id={'recurringTaskSchedulesGrid'}
            className="ag-theme-balham"
            style={{
              height: '100%',
              width: '100%',
              minHeight: '200px',
            }}
          >
            <AgGridReact
              onGridReady={(params) => {
                defaultOnGridReady(
                  params,
                  recurringTaskSchedules,
                  isRefetchingSchedules,
                  gridComponentStates.setGridApi,
                );
              }}
              frameworkComponents={{
                actionsRenderer: GenericGridActions,
                tagsRenderer: TaskGridTags,
                // dateAndCreationIconRenderer: TaskGridDateAndCreationIcon,
                summaryRenderer: TaskGridSummary,
                assignedToQuickSelectRenderer: MemberSelect,
              }}
              onRowDoubleClicked={handleRowDoubleClick}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              tooltipShowDelay={450}
              defaultColDef={getDefaultColDef()}
              getRowClass={(params: RowClassParams) => {
                const schedule = params.data as RecurringTaskScheduleType;
                if (
                  !schedule.templateTaskToCreate.assignedToMember ||
                  !schedule.templateTaskToCreate.assignedToMember.account
                ) {
                  return styles.rowWarning;
                } else {
                  // No classes
                  return;
                }
              }}
              // Normally we pass in row data via api on react-query onSuccess instead.
              // Otherwise this comes through as undefined on user tab switching which breaks loading spinner
              // However, apparently onSuccess doesn't get called when the data was cached
              // That means when you flip Legal to Station Expansion and back to Legal, Legal Cases don't show up
              // Using rowData fixes this, but I'm hoping for a better solution later.
              rowData={recurringTaskSchedules}
              statusBar={getDefaultStatusBar()}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {schedulePermissions &&
            hasPermission(schedulePermissions, 'add_recurringtaskschedule') &&
            !props.hideCreateButton && (
              <Button variant="primary" id="createRecurringTask" onClick={onCreateClick}>
                Create a recurring task schedule <i className="bi bi-plus" />
              </Button>
            )}
        </Col>
      </Row>
      <GenericDeleteDialog
        state={gridComponentStates.deleteDialogState}
        onCancel={gridComponentStates.closeDeleteDialog}
        onOk={(idToDelete) =>
          handleDeleteRecurringTaskScheduleDialogOk(
            idToDelete,
            gridComponentStates.closeDeleteDialog,
            gridComponentStates.disableSubmissionDeleteDialog,
            refetchSchedules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        objectTypeString="recurring task schedule"
      />
      <GenericRequestChangeDialog
        state={gridComponentStates.requestChangeDialogState}
        onOk={(requestedChange: RequestedChangeToSend) =>
          genericHandleRequestChangeOk(
            requestedChange,
            gridComponentStates.closeRequestChangeDialog,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onCancel={gridComponentStates.closeRequestChangeDialog}
        objectTypeString={'recurring task schedule'}
      />
      <ModifyRecurringTaskScheduleDialog
        selectedTeam={props.selectedTeam}
        // Should I change how I pass this state somehow?
        state={gridComponentStates.modifyDialogState}
        onReplaceAdaptedFields={(scheduleToReplace) => {
          return replaceAdaptedRecurringFieldsWithCore(scheduleToReplace).then(() => {
            console.log('refetching');
            refetchSchedules();
          })
        }}
        onCancel={gridComponentStates.closeModifyDialog}
        onOk={(modifySchedule, idToUpdate) =>
          defaultHandleModifyRecurringTaskScheduleDialogOk(
            modifySchedule,
            idToUpdate,
            gridComponentStates.closeModifyDialog,
            refetchSchedules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onDeleteIcon={handleModifyDialogDeleteIcon}
      />
    </Container>
  );
};

// TODO should I make this generic, too?
function handleDeleteRecurringTaskScheduleDialogOk(
  idToDelete: number,
  closeDeleteDialog: () => void,
  disableSubmissionDeleteDialog: () => void,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  disableSubmissionDeleteDialog();
  return axios.delete(RECURRING_TASK_SCHEDULES_URL + '/' + idToDelete + '/').then((response) => {
    closeDeleteDialog();
    addFeedbackMessage({
      key: createFeedbackMessageKey('recurringTaskSchedule', 'delete', idToDelete),
      status: 'success',
      messageBody: <span>Recurring task schedule deleted successfully.</span>,
    });
    refetch();
  });
}

export default RecurringTaskSchedulesGrid;
