import { Card, Col, Container, Dropdown, Row } from 'react-bootstrap';
import FieldCoreProcessType from '../../domain/fieldCoreProcess';
import CoreRecurringTaskScheduleGrid from '../../components/fcp/tasks/recurring/CoreRecurringTaskScheduleGrid/CoreRecurringTaskScheduleGrid';
import CoreTriggerRuleGrid from '../../components/fcp/tasks/trigger/CoreTriggerRuleGrid/CoreTriggerRuleGrid';
import CoreRecurringGoalScheduleGrid from '../../components/fcp/goals/recurring/CoreRecurringGoalScheduleGrid/CoreRecurringGoalScheduleGrid';
import React from 'react';
import TeamType from '../../domain/team';
import TriggerRuleGrid from '../../components/trigger/TriggerRuleGrid/TriggerRuleGrid';
import RecurringTaskSchedulesGrid from '../../components/tasks/recurring/RecurringTaskSchedulesGrid/RecurringTaskSchedulesGrid';
import RecurringGoalScheduleGrid from '../../components/goals/recurring/RecurringGoalScheduleGrid/RecurringGoalScheduleGrid';
import { GenericOption } from '../../formik/FormikSelect/FormikSelect';
import Select, { OnChangeValue } from 'react-select';
import {
  CoreCombinedAssignmentsGrid
} from '../../components/fcp/CoreCombinedAssignmentsGrid/CoreCombinedAssignmentsGrid';
import { useGetFpcs } from '../../components/fcp/apis/fcp_api';

type FieldCoreAssignmentsProps = {
  selectedTeam: TeamType | null;
  canViewTeamCoreAssignments: boolean;
  canViewGlobalCoreAssignments: boolean;
};

const GLOBAL = 'Global';
const THIS_TEAM = 'This team (change team on top right)';

function getDefaultArea(props: FieldCoreAssignmentsProps) {
  if (props.canViewGlobalCoreAssignments) {
    return GLOBAL;
  } else if (props.canViewTeamCoreAssignments && props.selectedTeam) {
    return props.selectedTeam?.name;
  } else {
    return null;
  }
}

// function getCategories(fieldCoreProcesses: FieldCoreProcessType[]) {
//   return fieldCoreProcesses
//     ? groupBy<FieldCoreProcessType>(fieldCoreProcesses, 'category').sort((a, b) => ('' + a.key).localeCompare('' + b.key))
//     : [];
// }

const FieldCoreAssignments = (props: FieldCoreAssignmentsProps) => {
  const [area, setArea] = React.useState<string | null>(getDefaultArea(props));
  const [activeFieldCoreProcess, setActiveFieldCoreProcess] = React.useState<FieldCoreProcessType | null>(null);

  let filterToInstalledTeam;
  let enabled = true;
  if (area === THIS_TEAM) {
    filterToInstalledTeam = props.selectedTeam?.id;
    if(filterToInstalledTeam === undefined) {
      enabled = false;
    }
  } else if (area === GLOBAL) {
    filterToInstalledTeam = null;
  }

  const { isLoading: isFcpLoading, data: fcpData, error: fcpError } = useGetFpcs(filterToInstalledTeam, enabled);

  if (isFcpLoading) return <p>Loading...</p>;
  if (props?.selectedTeam === undefined) {
    return <p>You are not a member of any team, contact an administrator to add you to a team.</p>;
  }
  if (props?.selectedTeam && (fcpError || fcpData === undefined)) return <p>Error!</p>;
  if (fcpError || fcpData === undefined) return <p>Error!</p>;

  const fieldCoreProcesses = fcpData.data as FieldCoreProcessType[];
  const fieldCoreProcessOptions = fieldCoreProcesses.map((fieldCoreProcess) => {
    const optionLabel = fieldCoreProcess.name;
    return {
      label: optionLabel,
      value: fieldCoreProcess,
    };
  });

  const handleFcpChange = (newOption: OnChangeValue<GenericOption, false>) => {
    const newFcp = newOption?.value as FieldCoreProcessType;
    setActiveFieldCoreProcess(newFcp);
  };

  const handleAreaChange = (type: string) => {
    setArea(type);
  };

  // Needed when switching from Nepal to SA, the selected FCP stays the same but without this the dropdown clears
  const getFcpSelectValue = () => {
    return fieldCoreProcessOptions.find((fcp) => fcp.value === activeFieldCoreProcess);
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row>
        <Col>
          <h4 className="text-center font-weight-bold">Field Core Process Assignments</h4>
        </Col>
      </Row>
      <Row className="pb-1 pe-5">
        <Col className="d-flex">
          <Dropdown className="pr-1">
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              {area}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.selectedTeam && props.canViewTeamCoreAssignments && (
                <Dropdown.Item
                  onClick={() => {
                    handleAreaChange(THIS_TEAM);
                  }}
                >
                  {THIS_TEAM}
                </Dropdown.Item>
              )}
              {props.canViewGlobalCoreAssignments && (
                <Dropdown.Item
                  onClick={() => {
                    handleAreaChange(GLOBAL);
                  }}
                >
                  {GLOBAL}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          {area && fieldCoreProcessOptions && (
            <div style={{ width: '300px' }}>
              <Select
                placeholder="Select an FCP"
                value={getFcpSelectValue()}
                onChange={handleFcpChange}
                options={fieldCoreProcessOptions}
                isClearable={true}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row  className='d-flex flex-column flex-grow-1'>
        {/* TODO combine core and non-core versions of the components together?
             Note that they have different columns, specifically assignee which can be 'Quick selected'
        */}
        {activeFieldCoreProcess && area === GLOBAL && (
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <div>
                <h3 style={{ textAlign: 'center' }}>Recurring Tasks</h3>
                <CoreRecurringTaskScheduleGrid fieldCoreProcess={activeFieldCoreProcess} />
                <h3 style={{ textAlign: 'center' }}>Event Triggered Tasks</h3>
                <CoreTriggerRuleGrid fieldCoreProcess={activeFieldCoreProcess} />
                <h3 style={{ textAlign: 'center' }}>Goals</h3>
                <CoreRecurringGoalScheduleGrid fieldCoreProcess={activeFieldCoreProcess} />
              </div>
            </Card.Body>
          </Card>
        )}
        {!activeFieldCoreProcess && area === GLOBAL && (
          <Card style={{ width: '100%' }}  className='d-flex flex-column flex-grow-1'>
            <Card.Body  className='d-flex flex-column flex-grow-1'>
              <CoreCombinedAssignmentsGrid />
            </Card.Body>
          </Card>
        )}
        {activeFieldCoreProcess && area !== GLOBAL && props.selectedTeam && (
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <div>
                <h3 style={{ textAlign: 'center' }}>Recurring Tasks</h3>
                <RecurringTaskSchedulesGrid
                  selectedTeam={props.selectedTeam}
                  fieldCoreProcesses={[activeFieldCoreProcess]}
                  memberQuickSelect={true}
                  hideCreateButton={true}
                />
                <h3 style={{ textAlign: 'center' }}>Event Triggered Tasks</h3>
                <TriggerRuleGrid
                  selectedTeam={props.selectedTeam}
                  fieldCoreProcesses={[activeFieldCoreProcess]}
                  memberQuickSelect={true}
                  hideCreateButton={true}
                />
                <h3 style={{ textAlign: 'center' }}>Goals</h3>
                <RecurringGoalScheduleGrid
                  selectedTeam={props.selectedTeam}
                  fieldCoreProcesses={[activeFieldCoreProcess]}
                  memberQuickSelect={true}
                  hideCreateButton={true}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Row>
    </Container>
  );
};

export default FieldCoreAssignments;
