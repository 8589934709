import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import TeamMemberType from '../../domain/teamMember';
import { TEAM_MEMBERS_URL } from '../../constants/urls';

async function getTeamMembersForTeam(teamId: number) {
  const requestConfig = {
    params: {
      team: teamId,
    },
  } as AxiosRequestConfig;
    const response = await axios.get<TeamMemberType[]>(TEAM_MEMBERS_URL, requestConfig);
    return response.data;
}
function useGetTeamMembersForTeam(teamId: number) {
  const useQueryConfig = {
    staleTime: 100000, // Cache for this long, in ms
  };
  return useQuery(
    ['getTeamMembersForTeam', teamId],
    () => getTeamMembersForTeam(teamId),
    useQueryConfig,
  );
}

export {useGetTeamMembersForTeam}