import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import { GET_TAGS_URL } from '../../constants/urls';
import TagType from '../../domain/tag';

async function getTags() {
  const requestConfig = {
    // No parameters
  } as AxiosRequestConfig;
  const response = await axios.get<TagType[]>(GET_TAGS_URL, requestConfig);
  return response.data;
}

function useGetTags() {
  const useQueryConfig = {
    staleTime: 100000, // Cache for this long, in ms
  };
  return useQuery(
    ['getTags'],
    () => getTags(),
    useQueryConfig,
  );
}

export { useGetTags };