import axios, { AxiosResponse } from 'axios';
import RecurringTaskScheduleType from '../../domain/recurringTaskSchedule';
import { RECURRING_TASK_SCHEDULES_URL } from '../../constants/urls';

function replaceAdaptedRecurringFieldsWithCore(recurringTaskSchedule: RecurringTaskScheduleType) {
  return axios.post<RecurringTaskScheduleType, AxiosResponse<RecurringTaskScheduleType>>(
    `${RECURRING_TASK_SCHEDULES_URL}/${recurringTaskSchedule.id}/replace-adaptable-fields/`,
    recurringTaskSchedule,
  );
}

export {replaceAdaptedRecurringFieldsWithCore}