import {
  defaultOnGridReady,
  getDefaultColDef, getDefaultStatusBar, useGridComponentStates,
} from '../../../helpers/GridComponentHelpers';
import CoreRecurringGoalScheduleType from '../../../domain/coreRecurringGoalSchedule';
import React from 'react';
import { HQ_TIMEZONE } from '../../../constants/globals';
import axios, { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import {
  CORE_RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL,
  CORE_RECURRING_GOAL_SCHEDULES_URL,
  CORE_RECURRING_TASK_SCHEDULE_PERMISSIONS_URL,
  CORE_RECURRING_TASK_SCHEDULES_URL, CORE_TRIGGER_RULE_PERMISSIONS_URL,
  CORE_TRIGGER_RULE_URL,
} from '../../../constants/urls';
import PermissionType from '../../../domain/permission';
import CoreRecurringTaskScheduleType from '../../../domain/coreRecurringTaskSchedule';
import CoreTriggerRuleType from '../../../domain/coreTriggerRule';
import { Col, Container, Row } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import GenericGridActions from '../../GenericGridActions/GenericGridActions';
import TaskGridSummary from '../../tasks/TaskGridSummary/TaskGridSummary';
import FieldCoreProcessType from '../../../domain/fieldCoreProcess';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import ModifyCoreRecurringGoalScheduleDialog
  , {
  defaultHandleModifyCoreRecurringGoalScheduleDialogOk,
} from '../goals/recurring/ModifyCoreRecurringGoalScheduleDialog/ModifyCoreRecurringGoalScheduleDialog';
import CoreTriggerRule from '../../../domain/coreTriggerRule';
import ModifyCoreTriggerRuleDialog, {
  defaultHandleModifyCoreTriggerRuleDialogOk
} from '../tasks/trigger/ModifyCoreTriggerRuleDialog/ModifyCoreTriggerRuleDialog';
import ModifyCoreRecurringTaskScheduleDialog
  , {
  defaultHandleModifyCoreRecurringTaskScheduleDialogOk,
} from '../tasks/recurring/ModifyCoreRecurringTaskScheduleDialog/ModifyCoreRecurringTaskScheduleDialog';
import GenericDeleteDialog from '../../GenericDeleteDialog/GenericDeleteDialog';
import { handleDeleteCoreTriggerRuleDialogOk } from '../tasks/trigger/CoreTriggerRuleGrid/CoreTriggerRuleGrid';
import {
  handleDeleteCoreRecurringTaskScheduleDialogOk
} from '../tasks/recurring/CoreRecurringTaskScheduleGrid/CoreRecurringTaskScheduleGrid';
import {
  handleDeleteCoreRecurringGoalScheduleDialogOk
} from '../goals/recurring/CoreRecurringGoalScheduleGrid/CoreRecurringGoalScheduleGrid';
import _ from 'lodash';
import FcpNameWithAlertIcons from '../../FcpNameWithAlertIcons/FcpNameWithAlertIcons';

type CoreCombinedAssignmentsGridProps = {};

type CoreAssignmentType = {
  id: number;
  type?: string;

  frequency?: string;
  startDateTime?: string;

  summaryTemplate: string;
  linkUrlTemplate: string;
  descriptionTemplate: string;
  suggestedStaff: string;

  totalPoints: number;

  onNcr: boolean;
  onSmr: boolean;

  fieldCoreProcess: FieldCoreProcessType;
}

const getColumns = (openModifyDialog: (objectToModify: CoreAssignmentType) => void, hqTimezone: string) => {
  return [
    {
      field: 'fieldCoreProcess.department',
      headerName: 'Dept.',
      width: 80,
    },
    {
      field: 'fieldCoreProcess.name',
      headerName: 'FCP',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
      cellRenderer: FcpNameWithAlertIcons,
    },
    {
      field: 'summaryTemplate',
      headerName: 'Summary',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 2,
      cellRenderer: TaskGridSummary,
    },
    {
      headerName: 'Type',
      width: 100,
      cellRenderer: (params: ICellRendererParams) => {
        const data = params.data;
        if (data.frequency) {
          return data.frequency + ' ' + data.type;
        } else if (data.createOnEventType) {
          return 'Trigger after ' + data.createOnEventType.name;
        }
      },
    },
    {
      field: 'suggestedStaff',
      headerName: 'Suggested Staff',
      flex: 1,
    },
    {
      field: 'descriptionTemplate',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'totalPoints',
      headerName: 'Weight',
      width: 90,
    },
    {
      field: 'onNcr',
      headerName: 'NCR?',
      width: 50,
      cellRenderer: (params: any) => {
        return params.value ? <span>✅</span> : <span>❌</span>;
      }
    },
    {
      field: 'onSmr',
      headerName: 'SMR?',
      width: 50,
      cellRenderer: (params: any) => {
        return params.value ? <span>✅</span> : <span>❌</span>;
      }
    },
    {
      headerName: 'Actions',
      width: 90,
      cellRenderer: GenericGridActions,
      cellRendererParams: {
        onEditClick: (ruleToEdit: CoreAssignmentType) => {
          openModifyDialog(ruleToEdit);
        },
      },
    },
  ];
};
const useGetCoreCombinedAssignments = () => {
  const coreRecurringGoalSchedulesRequestConfig = {} as AxiosRequestConfig;

  const {
    isLoading: isLoadingGoals,
    isRefetching: isRefetchingGoals,
    error: goalsError,
    data: coreGoalData,
    refetch: refetchGoals,
  } = useQuery(
    ['getCoreRecurringGoalSchedules', coreRecurringGoalSchedulesRequestConfig],
    () => {
      return axios.get<CoreRecurringGoalScheduleType[]>(
        CORE_RECURRING_GOAL_SCHEDULES_URL,
        coreRecurringGoalSchedulesRequestConfig,
      );
    },
  );

  const getCoreRecurringGoalSchedulePermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const {
    error: coreRecurringGoalPermissionsError,
    data: coreRecurringGoalPermissionsData,
    isLoading: isCoreGoalPermissionsLoading,
  } = useQuery(
    ['getCoreRecurringGoalSchedulePermissions', getCoreRecurringGoalSchedulePermissionsRequestConfig],
    () => {
      return axios.get<PermissionType[]>(
        CORE_RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL,
        getCoreRecurringGoalSchedulePermissionsRequestConfig,
      );
    },
  );

  // --------------------------------------------------

  const coreRecurringTaskSchedulesRequestConfig = {} as AxiosRequestConfig;

  const {
    isLoading: isLoadingRecurring,
    isRefetching: isRefetchingRecurring,
    error: recurringError,
    data: recurringData,
    refetch: refetchRecurring,
  } = useQuery(
    ['getCoreRecurringTaskSchedulesForFcp', coreRecurringTaskSchedulesRequestConfig],
    () => {
      return axios.get<CoreRecurringTaskScheduleType[]>(
        CORE_RECURRING_TASK_SCHEDULES_URL,
        coreRecurringTaskSchedulesRequestConfig,
      );
    },
  );

  const getRecurringTaskSchedulePermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const {
    error: coreRecurringPermissionsError,
    data: coreRecurringPermissionsData,
    isLoading: isCoreRecurringPermissionsLoading,
  } = useQuery(['getRecurringTaskSchedulePermissions', getRecurringTaskSchedulePermissionsRequestConfig], () => {
    return axios.get<PermissionType[]>(
      CORE_RECURRING_TASK_SCHEDULE_PERMISSIONS_URL,
      getRecurringTaskSchedulePermissionsRequestConfig,
    );
  });

  //--------------------------------------------------
  const coreTriggerRulesRequestConfig = {
    params: {
      // No parameters
    },
  };
  const {
    isLoading: isLoadingRules,
    isRefetching: isRefetchingRules,
    error: rulesError,
    data: rulesData,
    refetch: refetchRules,
  } = useQuery(
    ['getCoreTriggerRulesForFcp', coreTriggerRulesRequestConfig],
    () => {
      return axios.get<CoreTriggerRuleType[]>(CORE_TRIGGER_RULE_URL, coreTriggerRulesRequestConfig);
    },
  );

  const getCoreTriggerRulePermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const {
    error: rulePermissionsError,
    data: rulePermissionsData,
    isLoading: isRulePermissionsLoading,
  } = useQuery(['getCoreTriggerRulePermissions', getCoreTriggerRulePermissionsRequestConfig], () => {
    return axios.get<PermissionType[]>(CORE_TRIGGER_RULE_PERMISSIONS_URL, getCoreTriggerRulePermissionsRequestConfig);
  });
  // ---------------------------------------------------------------------

  let hasError = false;
  if (recurringError || coreRecurringPermissionsError || goalsError || coreRecurringGoalPermissionsError || rulePermissionsError || rulesError) {
    hasError = true;
  }

  let isBlocked = false;
  const coreGoalPermissionsBlocked = !isCoreGoalPermissionsLoading &&
    (!coreRecurringGoalPermissionsData?.data || coreRecurringGoalPermissionsData?.data.length === 0);
  const coreRecurringTaskPermissionsBlocked = !isCoreRecurringPermissionsLoading &&
    (!coreRecurringPermissionsData?.data || coreRecurringPermissionsData?.data.length === 0);
  const rulePermissionsBlocked = !isRulePermissionsLoading &&
    (!rulePermissionsData?.data || rulePermissionsData?.data.length === 0);
  if (
    coreGoalPermissionsBlocked || coreRecurringTaskPermissionsBlocked || rulePermissionsBlocked
  ) {
    isBlocked = true;
  }

  let isRefetching = isRefetchingRecurring || isRefetchingGoals || isRefetchingRules;
  const isLoading = isLoadingRecurring || isLoadingGoals || isLoadingRules;

  const refetch = () => {
    refetchRecurring();
    refetchGoals();
    refetchRules();
  };

  return {
    isLoading: isLoading,
    hasError: hasError,
    isBlocked: isBlocked,
    isRefetching: isRefetching,
    refetch: refetch,
    coreGoalData: coreGoalData,
    coreRecurringData: recurringData,
    coreRulesData: rulesData,
  };
};
export const CoreCombinedAssignmentsGrid = (props: CoreCombinedAssignmentsGridProps) => {
  const [, setGridApi] = React.useState<null | GridApi>(null);

  let goalComponentStates = useGridComponentStates<CoreRecurringGoalScheduleType>();
  let recurringComponentStates = useGridComponentStates<CoreRecurringTaskScheduleType>();
  let ruleComponentStates = useGridComponentStates<CoreTriggerRuleType>();

  const openModifyDialog = (coreAssignment: CoreAssignmentType) => {
    if (coreAssignment.type === 'Recurring Task') {
      recurringComponentStates.openModifyDialog(coreAssignment as CoreRecurringTaskScheduleType);
    } else if (coreAssignment.type === 'Goal') {
      goalComponentStates.openModifyDialog(coreAssignment as CoreRecurringGoalScheduleType);
    } else if (coreAssignment.type === 'Trigger') {
      ruleComponentStates.openModifyDialog(coreAssignment as CoreTriggerRuleType);
    } else {
      throw new Error('Unknown type');
    }
  };

  const [columnDefs] = React.useState(getColumns(openModifyDialog, HQ_TIMEZONE));

  const combinedCoreAssignmentResponse = useGetCoreCombinedAssignments();

  if (combinedCoreAssignmentResponse.isBlocked) {
    return <p>You don't have permission for everything on this page</p>;
  } else if (combinedCoreAssignmentResponse.hasError) {
    return <p>Error!</p>;
  }
  let combinedCoreAssignments: any[] | undefined = undefined;
  if (!combinedCoreAssignmentResponse.isLoading) {
    combinedCoreAssignments = [];
    combinedCoreAssignmentResponse.coreRecurringData?.data?.forEach((coreRecurringTask) => {
      combinedCoreAssignments!.push({
        type: 'Recurring Task',
        ...coreRecurringTask,
      });
    });
    combinedCoreAssignmentResponse.coreGoalData?.data?.forEach((coreRecurringGoal) => {
      combinedCoreAssignments!.push({
        type: 'Goal',
        ...coreRecurringGoal,
      });
    });
    combinedCoreAssignmentResponse.coreRulesData?.data?.forEach((coreTriggerRule) => {
      combinedCoreAssignments!.push({
        type: 'Trigger',
        ...coreTriggerRule,
      });
    });
    combinedCoreAssignments = _.sortBy(combinedCoreAssignments, ['fieldCoreProcess.department', 'fieldCoreProcess.order', 'fieldCoreProcess.name', 'type', 'summaryTemplate']);
  }

  return (
    <Container
      fluid
      className={
        //styles.container +
        ' d-flex flex-column flex-grow-1'
      }
    >
      <Row className='d-flex flex-column flex-grow-1'>
        <Col>
          <div
            className='ag-theme-balham'
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <AgGridReact<CoreAssignmentType[]>
              onGridReady={(params) => {
                defaultOnGridReady(
                  params,
                  combinedCoreAssignments,
                  combinedCoreAssignmentResponse.isRefetching,
                  setGridApi,
                );
              }}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              tooltipShowDelay={450}
              defaultColDef={getDefaultColDef()}
              rowData={combinedCoreAssignments}
              statusBar={getDefaultStatusBar()}
            ></AgGridReact>
          </div>
        </Col>
      </Row>
      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    {rulePermissions && hasPermission(rulePermissions, 'add_coretriggerrule') && (*/}
      {/*      <Button onClick={onCreateClick}>*/}
      {/*        Create a core trigger rule <i className="bi bi-plus" />*/}
      {/*      </Button>*/}
      {/*    )}*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <ModifyCoreTriggerRuleDialog
        // Should I change how I pass this state somehow?
        state={ruleComponentStates.modifyDialogState}
        onCancel={ruleComponentStates.closeModifyDialog}
        onOk={(modifyRule, idToUpdate) =>
          defaultHandleModifyCoreTriggerRuleDialogOk(
            modifyRule,
            idToUpdate,
            ruleComponentStates.closeModifyDialog,
            combinedCoreAssignmentResponse.refetch,
            ruleComponentStates.addFeedbackMessage,
          )
        }
        onDeleteIcon={(updatedSchedule: CoreTriggerRule) => {
          ruleComponentStates.closeModifyDialog();
          ruleComponentStates.openDeleteDialog(updatedSchedule);
        }}
      />
      <GenericDeleteDialog
        state={ruleComponentStates.deleteDialogState}
        onCancel={ruleComponentStates.closeDeleteDialog}
        onOk={(idToDelete) =>
          handleDeleteCoreTriggerRuleDialogOk(
            idToDelete,
            ruleComponentStates.closeDeleteDialog,
            ruleComponentStates.disableSubmissionDeleteDialog,
            combinedCoreAssignmentResponse.refetch,
            ruleComponentStates.addFeedbackMessage,
          )
        }
        objectTypeString="core trigger rule"
      />
      <ModifyCoreRecurringGoalScheduleDialog
        // Should I change how I pass this state somehow?
        state={goalComponentStates.modifyDialogState}
        onCancel={goalComponentStates.closeModifyDialog}
        onOk={(modifyRule, idToUpdate) =>
          defaultHandleModifyCoreRecurringGoalScheduleDialogOk(
            modifyRule,
            idToUpdate,
            goalComponentStates.closeModifyDialog,
            combinedCoreAssignmentResponse.refetch,
            goalComponentStates.addFeedbackMessage,
          )
        }
        onDeleteIcon={(updatedSchedule: CoreRecurringGoalScheduleType) => {
          goalComponentStates.closeModifyDialog();
          goalComponentStates.openDeleteDialog(updatedSchedule);
        }}
      />
      <GenericDeleteDialog
        state={goalComponentStates.deleteDialogState}
        onCancel={goalComponentStates.closeDeleteDialog}
        onOk={(idToDelete) =>
          handleDeleteCoreRecurringGoalScheduleDialogOk(
            idToDelete,
            goalComponentStates.closeDeleteDialog,
            goalComponentStates.disableSubmissionDeleteDialog,
            combinedCoreAssignmentResponse.refetch,
            goalComponentStates.addFeedbackMessage,
          )
        }
        objectTypeString="core recurring goal schedule"
      />
      <ModifyCoreRecurringTaskScheduleDialog
        // Should I change how I pass this state somehow?
        state={recurringComponentStates.modifyDialogState}
        onCancel={recurringComponentStates.closeModifyDialog}
        onOk={(modifyRule, idToUpdate) =>
          defaultHandleModifyCoreRecurringTaskScheduleDialogOk(
            modifyRule,
            idToUpdate,
            recurringComponentStates.closeModifyDialog,
            combinedCoreAssignmentResponse.refetch,
            recurringComponentStates.addFeedbackMessage,
          )
        }
        onDeleteIcon={(updatedSchedule: CoreRecurringTaskScheduleType) => {
          recurringComponentStates.closeModifyDialog();
          recurringComponentStates.openDeleteDialog(updatedSchedule);
        }}
      />
      <GenericDeleteDialog
        state={recurringComponentStates.deleteDialogState}
        onCancel={recurringComponentStates.closeDeleteDialog}
        onOk={(idToDelete) =>
          handleDeleteCoreRecurringTaskScheduleDialogOk(
            idToDelete,
            recurringComponentStates.closeDeleteDialog,
            recurringComponentStates.disableSubmissionDeleteDialog,
            combinedCoreAssignmentResponse.refetch,
            recurringComponentStates.addFeedbackMessage,
          )
        }
        objectTypeString="core recurring task schedule"
      />
    </Container>
  );
};
