import axios from 'axios';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { TRIGGER_RULE_URL } from '../../../constants/urls';
import TriggerRuleType, { TriggerRuleTypeToSend } from '../../../domain/triggerRule';
import TeamType from '../../../domain/team';
import FormikController from '../../../formik/FormikSelect/FormikController';
import { Mode } from '../../../state_types/mode';
import { getNameOfCreationUser } from '../../../helpers/AuditFieldHelper';
import { createFeedbackMessageKey, FeedbackMessage } from '../../FeedbackMessages/FeedbackMessages';
import { getSimpleModal, ModifyDialogState } from '../../../helpers/GridComponentHelpers';
import { getFormikReadOnlyField } from '../../../formik/FormikHelpers';
import { getCallSignWithPositionAndName } from '../../../helpers/TeamMembershipHelpers';
import { useState } from 'react';
import { useGetTeamMembersForTeam } from '../../apis/team_members_api';
import { useGetTags } from '../../apis/tags_api';
import { useGetEventTypes } from '../../apis/event_types_api';
import DefaultTooltip from '../../DefaultTooltip/DefaultTooltip';
import CoreSyncButton from '../../shared/CoreSyncButton';

export interface ModifyTriggerRuleDialogProps {
  selectedTeam: TeamType;
  state: ModifyDialogState<TriggerRuleType>;
  onReplaceAdaptedFields: (ruleToReplace: TriggerRuleType) => Promise<void>;
  onOk: (updatedRuleFields: TriggerRuleTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (ruleToDelete: TriggerRuleType) => void;
}

const getMode = (state: ModifyDialogState<TriggerRuleType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Trigger Rule';
};

const ModifyTriggerRuleDialog = (props: ModifyTriggerRuleDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  const [isUpdatingAdaptations, setIsReplacingAdaptations] = useState(false);

  // If task to edit is set, assume edit.
  // Otherwise, assume create
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };
  // const task = state.taskToEdit;

  const { isLoading: isLoadingTeamMembers, error: teamMembersError, data: teamMembers, refetch: refetchTeamMembers } = useGetTeamMembersForTeam(props.selectedTeam.id)
  const { isLoading: isLoadingTags, error: tagsError, data: tags, refetch: refetchTags } = useGetTags();
  const { isLoading: isLoadingEventTypes, error: eventTypesError, data: eventTypes, refetch: refetchEventTypes } = useGetEventTypes();

  if (isLoadingTeamMembers || isLoadingTags || isLoadingEventTypes) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  if (teamMembersError || tagsError || eventTypesError) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  if (!teamMembers || !tags || !eventTypes) return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');

  const teamMemberOptions = teamMembers.map((teamMember) => {
    const optionLabel = getCallSignWithPositionAndName(teamMember);
    return {
      label: optionLabel,
      value: teamMember.id,
    };
  });

  const tagOptions = tags.map((tag) => {
    return {
      label: tag.name,
      // Value is the object itself. This makes it easy for Formik to pass to backend
      // TODO change to id?
      value: tag,
    };
  });

  const eventTypeOptions = eventTypes.map((eventType) => {
    return {
      label: eventType.name,
      value: eventType.id,
    };
  });

  // TODO should this be in some sort of useState hook?
  var initialStateOfEditableRuleFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const rule = state.objectToModify!;

    // Copy only editable fields
    initialStateOfEditableRuleFields = {
      // Not editable
      // id: task.id,
      createOnEventType: rule.createOnEventType.id,
      // Not editable
      // creationUser: task.creationUser,
      // Not editable in the UI, but needed on create, so included
      // TODO now create is separated, is above comment still valid???
      team: rule.team.id,

      templateTaskToCreate: {
        daysToComplete: rule.templateTaskToCreate.daysToComplete,
        summaryTemplate: rule.templateTaskToCreate.summaryTemplate,
        descriptionTemplate: rule.templateTaskToCreate.descriptionTemplate,
        linkUrlTemplate: rule.templateTaskToCreate.linkUrlTemplate,
        // Can be undefined, for example when a new FCP is cascaded
        assignedToMember: rule.templateTaskToCreate.assignedToMember?.id,
        totalPoints: rule.templateTaskToCreate.totalPoints,
        tags: rule.templateTaskToCreate.tags,
        // Not editable in the UI, but needed on create, so included
        // TODO now create is separated, is above comment still valid???
        team: rule.team.id,
      },
    } as TriggerRuleTypeToSend;

    const createdByName = getNameOfCreationUser(rule);
    createdByText = getFormikReadOnlyField('Created by:', createdByName);

    deleteButton = (
      <Button
        className='bi-trash-fill mr-auto'
        variant='danger'
        onClick={() => {
          onDeleteIcon(rule);
        }}
      >
        Delete
      </Button>
    );

    onSubmit = (updatedRule: TriggerRuleTypeToSend | null, { setSubmitting }: any) => {
      console.log('update rule dialog ok hit', updatedRule);
      setTimeout(() => {
        if (updatedRule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('updatedRule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // TODO make this typescript cast have a validation or something
        onOk(updatedRule, rule.id).then(() => {
          // In case new tags were created
          refetchTags();
          refetchTeamMembers();
          refetchEventTypes();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableRuleFields = {
      createOnEventType: undefined,
      team: props.selectedTeam.id,

      templateTaskToCreate: {
        daysToComplete: undefined,
        summaryTemplate: '',
        descriptionTemplate: '',
        linkUrlTemplate: '',
        assignedToMember: undefined,
        totalPoints: undefined,
        tags: [],
        team: props.selectedTeam.id,
      },
      // Template task
    } as TriggerRuleTypeToSend;
    onSubmit = (newRule: TriggerRuleTypeToSend, { setSubmitting }: any) => {
      setTimeout(() => {
        if (newRule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new rule was null, contact developers');
          setSubmitting(false);
          return;
        }
        // TODO make this typescript cast have a validation or something
        onOk(newRule, null).then(() => {
          // In case new tags were created
          refetchTags();
          refetchTeamMembers();
          refetchEventTypes();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    createdByText = null;
  }

  const TriggerRuleFormSchema = Yup.object().shape(
    {
      createOnEventType: Yup.number().nullable().required('Required'),
      templateTaskToCreate: Yup.object({
        daysToComplete: Yup.number().required('Required'), // TODO make integer only
        summaryTemplate: Yup.string().required('Required'),
        descriptionTemplate: Yup.string().optional(),
        linkUrlTemplate: Yup.string().optional(),
        assignedToMember: Yup.number().nullable().required('Required'),
        totalPoints: Yup.number().required('Required'), // TODO make integer only
        // TODO tags?
      }),
    },
    // This prevents 'Cyclic dependency', not sure why. https://stackoverflow.com/a/59783840
    [['createOnEventType', 'createOnSmrTab']],
  );

  const replaceAdaptedFields = () => {
    setIsReplacingAdaptations(true)
    if (state.objectToModify) {
      props.onReplaceAdaptedFields(state.objectToModify).then(() => {
        setIsReplacingAdaptations(false)
        onCancel();
      })
    }
  };

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik
          initialValues={initialStateOfEditableRuleFields}
          validationSchema={TriggerRuleFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: any) => {


            const getAdaptedIcon = (
              newestVersion: string | number | undefined,
              adaptedFrom: string | number | undefined,
              isAdapted: boolean | undefined,
            ) => {
              if (!isAdapted) return null; // Return null if the adaptation is not present
              return (
                <DefaultTooltip id={'adapted'} tooltipBodyElement={<div>
                  <div><strong>Adapted from global FCP</strong></div>
                  {adaptedFrom && (adaptedFrom !== newestVersion) &&
                    <div><strong>Adapted From</strong>: {adaptedFrom}</div>}
                  <div><strong>Newest Version</strong>: {newestVersion}</div>
                </div>}>
                  <i
                    style={{
                      textDecoration: 'underline dotted',
                      color: 'grey',
                    }}
                    className='bi bi-tools'
                  />
                </DefaultTooltip>
              );
            };

            const adaptations = state.objectToModify?.adaptations;
            const createdByCore = state.objectToModify?.createdByCore;
            const createdByCoreVersion = state.objectToModify?.createdByCoreVersion;
            const isAdapted = state.objectToModify?.isAdapted;
            const isOutdatedVersion = state.objectToModify?.isOutdatedCoreVersion;

            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {getModalTitle(mode)}
                    <CoreSyncButton
                      isAdapted={Boolean(isAdapted)}
                      isOutdatedVersion={Boolean(isOutdatedVersion)}
                      createdByCore={createdByCore}
                      isUpdatingAdaptations={isUpdatingAdaptations}
                      onReplaceAdaptedFields={replaceAdaptedFields}
                      isSubmitting={formikProps.isSubmitting}
                      tooltipBody={
                        <div>
                          <h5>Will change to the following</h5>
                          <div><b>Summary</b>: {createdByCore?.summaryTemplate}</div>
                          <div><b>Event Type</b>: {createdByCore?.createOnEventType?.name}</div>
                          <div><b>Description</b>: {createdByCore?.descriptionTemplate}</div>
                          <div><b>Link</b>: {createdByCore?.linkUrlTemplate}</div>
                          <div><b>Weight</b>: {createdByCore?.totalPoints}</div>
                          <div><b>Days To Complete</b>: {createdByCore?.daysToComplete}</div>
                        </div>
                      }
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Only show createdByText on editing */}
                  {createdByText}
                  <FormikController
                    control='select'
                    name='createOnEventType'
                    label={
                      <span>Event Type {createdByCore && getAdaptedIcon(createdByCore.createOnEventType?.name, createdByCoreVersion?.createOnEventType?.name, adaptations?.createOnEventType)}</span>
                    }
                    options={eventTypeOptions}
                  />

                  <FormikController
                    control='input'
                    name='templateTaskToCreate.daysToComplete'
                    label={
                      <span>Days To Complete {createdByCore && getAdaptedIcon(createdByCore.daysToComplete, createdByCoreVersion?.daysToComplete, adaptations?.daysToComplete)}</span>
                    }
                  />
                  <FormikController
                    control='input'
                    name='templateTaskToCreate.summaryTemplate'
                    label={
                      <span>Summary {createdByCore && getAdaptedIcon(createdByCore.summaryTemplate, createdByCoreVersion?.summaryTemplate, adaptations?.summaryTemplate)}</span>
                    }
                  />
                  <FormikController
                    control='input'
                    name='templateTaskToCreate.descriptionTemplate'
                    label={
                      <span>Description (optional) {createdByCore && getAdaptedIcon(createdByCore.descriptionTemplate, createdByCoreVersion?.descriptionTemplate, adaptations?.descriptionTemplate)}</span>
                    }
                  />
                  <FormikController
                    control='input'
                    name='templateTaskToCreate.linkUrlTemplate'
                    label={
                      <span>Link (optional) {createdByCore && getAdaptedIcon(createdByCore.linkUrlTemplate, createdByCoreVersion?.linkUrlTemplate, adaptations?.linkUrlTemplate)}</span>
                    }
                  />
                  <FormikController
                    control='select'
                    name='templateTaskToCreate.assignedToMember'
                    label='Assigned To'
                    options={teamMemberOptions}
                  />
                  <FormikController
                    control='input'
                    name='templateTaskToCreate.totalPoints'
                    label={
                      <span>Weight {createdByCore && getAdaptedIcon(createdByCore.totalPoints, createdByCoreVersion?.totalPoints, adaptations?.totalPoints)}</span>
                    }
                  />
                  {/* 'startingOptions' because we are allowing the creation of new tags */}
                  <FormikController
                    control='tags'
                    name='templateTaskToCreate.tags'
                    label='Tags (optional)'
                    startingOptions={tagOptions}
                  />
                </Modal.Body>
                <Modal.Footer>
                  {/* Only show delete button on editing */}
                  {deleteButton}
                  <Button variant='secondary' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant='primary' type='submit' disabled={formikProps.isSubmitting || isUpdatingAdaptations}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      }
    </Modal>
  );
};

function defaultHandleModifyTriggerRuleDialogOk(
  modifiedRule: TriggerRuleTypeToSend,
  idToUpdate: number | null,
  closeModifyDialog: () => void,
  refetchRules: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  let promise;
  if (idToUpdate !== null) {
    console.log('Updating rule ' + idToUpdate, modifiedRule);
    // POST is for new stuff, PUT is for replacing task (must have ALL fields)
    // This uses PATCH, which loads old task and only updates fields you passed
    // https://stackoverflow.com/a/24241955/13815107
    promise = axios.patch(TRIGGER_RULE_URL + '/' + idToUpdate + '/', modifiedRule).then((response) => {
      closeModifyDialog();
      addFeedbackMessage({
        key: createFeedbackMessageKey('triggerRule', 'change', idToUpdate),
        status: 'success',
        messageBody: <span>Trigger rule updated successfully.</span>,
      });
      refetchRules();
    });
  } else {
    console.log('Creating rule', modifiedRule);
    if (modifiedRule.id) {
      console.error('Creating a rule but sending an id. Was this meant to be a modify?');
      promise = Promise.reject('Creating a rule but sending an id. Was this meant to be a modify?');
    } else {
      promise = axios.post(TRIGGER_RULE_URL + '/', modifiedRule).then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('triggerRule', 'create'),
          status: 'success',
          messageBody: <span>Trigger rule created successfully.</span>,
        });
        refetchRules();
      });
    }
  }
  return promise;
}

export default ModifyTriggerRuleDialog;
export { defaultHandleModifyTriggerRuleDialogOk };
