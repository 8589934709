import axios, { AxiosResponse } from 'axios';
import { TRIGGER_RULE_URL } from '../../constants/urls';
import TriggerRuleType from '../../domain/triggerRule';

function replaceAdaptedTriggerRuleFieldsWithCore(triggerRule: TriggerRuleType) {
  return axios.post<TriggerRuleType, AxiosResponse<TriggerRuleType>>(
    `${TRIGGER_RULE_URL}/${triggerRule.id}/replace-adaptable-fields/`,
    triggerRule,
  );
}

export {replaceAdaptedTriggerRuleFieldsWithCore}