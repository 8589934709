import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import RecurringTaskScheduleType from '../../domain/recurringTaskSchedule';
import { CORE_RECURRING_TASK_SCHEDULES_URL } from '../../constants/urls';

// This could be renamed to 'cascade_core_recurring_goal_schedules'
// and take a 'force_update' parameter if the backend was changed to accept it.
function addNewCoreRecurringTaskSchedules(teamToFilter: number, filterToFcpIds?: number[]) {
  const requestConfig = {
    params: {
      team: teamToFilter,
      fcps: filterToFcpIds
    },
  } as AxiosRequestConfig;
  const EMPTY_DATA_OBJECT = {};
  return axios.post<any, AxiosResponse<RecurringTaskScheduleType[]>>(
    `${CORE_RECURRING_TASK_SCHEDULES_URL}/cascade/`,
    EMPTY_DATA_OBJECT,
      requestConfig
  );
}

export {addNewCoreRecurringTaskSchedules}