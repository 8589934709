import React, { useContext, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { SelectedTeamMemberContext } from '../../../contexts/SelectedTeamMemberContext';
import TeamMemberType from '../../../domain/teamMember';
import TeamType from '../../../domain/team';
import { useSearchParams } from 'react-router-dom';
import { TEAM_MEMBER_SEARCH_PARAM } from '../../../constants/globals';
import { getObjectFromListById } from '../../../helpers/ObjectIdHelpers';
import { parseInt } from 'lodash';
import { useGetTeamMembersForTeam } from '../../apis/team_members_api';

type MemberTabsProps = {
  selectedTeam: TeamType;
  loggedInTeamMember: TeamMemberType | null;
};

const MemberTabs = (props: MemberTabsProps) => {
  // This component currently throws this error
  // `Cannot update a component (`Dashboard`) while rendering a different component (`MemberSelect`)`
  // I believe is caused by calls to selectedTeamMemberContext.setSelectedTeamMember()
  // These calls affect other components outside because of providers/consumers
  // See https://stackoverflow.com/a/62373122/13815107
  // The fix would involve useEffect() in some way, but I haven't found a way to fix
  //  it without breaking the automatic selection of the callSign tab of the logged in user
  const selectedTeamMemberContext = useContext(SelectedTeamMemberContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const assignedToStringQueryParam = searchParams.get(TEAM_MEMBER_SEARCH_PARAM);
  const idToFocusOn =
    assignedToStringQueryParam && parseInt(assignedToStringQueryParam)
      ? parseInt(assignedToStringQueryParam)
      : undefined;

  const { isLoading, error, data: teamMembers } = useGetTeamMembersForTeam(props.selectedTeam.id)
  useEffect(() => {
      // If we are calling the backend with a new team and getting new teammembers, clear the current teammember
      if (props.selectedTeam.id !== selectedTeamMemberContext?.selectedTeamMember?.team) {
        selectedTeamMemberContext.setSelectedTeamMember(null);
        // searchParams.delete(TEAM_MEMBER_SEARCH_PARAM);
      }
  }, [props.selectedTeam.id, selectedTeamMemberContext]);
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;
  if (teamMembers === undefined) return <p>No data</p>;

  if (idToFocusOn !== undefined) {
    const foundTeamMember = getObjectFromListById<TeamMemberType>(teamMembers, idToFocusOn);
    selectedTeamMemberContext.setSelectedTeamMember(foundTeamMember);
  } else if (props.loggedInTeamMember && selectedTeamMemberContext.selectedTeamMember === null) {
    selectedTeamMemberContext.setSelectedTeamMember(props.loggedInTeamMember);
    // searchParams.set(TEAM_MEMBER_SEARCH_PARAM, props.loggedInTeamMember.id.toString());
    // setSearchParams(searchParams);
  }

  const onTeamMemberButtonClick = (teamMember: TeamMemberType) => {
    selectedTeamMemberContext.setSelectedTeamMember(teamMember);
    // searchParams.set(TEAM_MEMBER_SEARCH_PARAM, teamMember.id.toString());
    // setSearchParams(searchParams);
  };

  return (
    // Make Staff bigger, don't wrap in span
    <span>
      <span
        style={{
          // bold is too bold
          fontWeight: 600,
          paddingRight: '8px',
        }}
      >
        Staff:
      </span>
      <ButtonGroup aria-label="Choose a role/teammember">
        {teamMembers.map((teamMember) => {
          return (
            <Button
              id={teamMember.callSign}
              key={teamMember.id}
              variant={getVariant(teamMember, selectedTeamMemberContext.selectedTeamMember)}
              onClick={() => onTeamMemberButtonClick(teamMember)}
            >
              {teamMember.callSign}
            </Button>
          );
        })}
      </ButtonGroup>
    </span>
  );
};

function getVariant(thisButtonsTeamMember: TeamMemberType, selectedTeamMember: TeamMemberType | null): string {
  if (selectedTeamMember !== null && selectedTeamMember.id === thisButtonsTeamMember.id) {
    return 'primary';
  } else if (thisButtonsTeamMember.account === null) {
    return 'warning';
  }
  return 'light';
}

export default MemberTabs;
