// import Box from '@material-ui/core/Box';
import { Alert, Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import RecurringTaskSchedulesGrid
  from '../../components/tasks/recurring/RecurringTaskSchedulesGrid/RecurringTaskSchedulesGrid';
import TeamType from '../../domain/team';
import { Link, useSearchParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import RecurringGoalScheduleGrid
  from '../../components/goals/recurring/RecurringGoalScheduleGrid/RecurringGoalScheduleGrid';
import Select from 'react-select';
import { createFcpOptions, useGetFcpsByDepartment } from '../../components/fcp/apis/fcp_api';
import { IdOption } from '../../formik/FormikSelect/FormikSelect';
import { addNewCoreRecurringTaskSchedules } from '../../components/apis/core_recurring_task_schedule_api';
import { createFeedbackMessageKey } from '../../components/FeedbackMessages/FeedbackMessages';
import FeedbackMessagesContext from '../../contexts/FeedbackMessagesContext';
import { addNewCoreRecurringGoalSchedules } from '../../components/apis/core_recurring_goal_schedule_api';
import { addNewCoreTriggerRules } from '../../components/apis/core_trigger_rule_api';

type RecurringTasksProps = {
  selectedTeam: TeamType;
};

const CountryAssignments = (props: RecurringTasksProps) => {
  const { addFeedbackMessage } = useContext(FeedbackMessagesContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const newTeamMemberId = searchParams.get('newTeamMember');
  const assignmentTypeAndIdToFocusOn = searchParams.get('assignmentToFocusOn');
  const [isAddMissingModalOpen, setIsAddMissingModalOpen] = useState(false);
  const [isAddMissingSubmitting, setIsAddMissingSubmitting] = useState(false);
  const [fcpsToAddMissing, setFcpsToAddMissing] = useState<readonly IdOption[]>([]);

  const {
    data: fcps,
  } = useGetFcpsByDepartment(props.selectedTeam.department, isAddMissingModalOpen);
  const fcpOptions = createFcpOptions(fcps);

  let recurringTaskScheduleIdToFocusOn = undefined;
  let recurringGoalScheduleIdToFocusOn = undefined;
  if (assignmentTypeAndIdToFocusOn && assignmentTypeAndIdToFocusOn.split('-').length === 2) {
    const type = assignmentTypeAndIdToFocusOn.split('-')[0];
    const id = parseInt(assignmentTypeAndIdToFocusOn.split('-')[1]);
    if (id) {
      if (type === 'recurringTask') {
        recurringTaskScheduleIdToFocusOn = id;
        console.debug('Trying to focus on recurring task ' + id);
      } else if (type === 'recurringGoal') {
        recurringGoalScheduleIdToFocusOn = id;
        console.debug('Trying to focus on recurring goal ' + id);
      } else {
        console.error('Unknown assignment type to focus on ' + type);
      }
    } else {
      console.error(
        'Unable to focus on ' + type + ' ' + assignmentTypeAndIdToFocusOn.split('-')[1] + ', id is not a number',
      );
    }
  }

  const addNewCoreAssignmentsClick = (fcpOptions: readonly IdOption[]) => {
    const fcpIds = fcpOptions.map((option) => option.value);
    const recurringPromise = addNewCoreRecurringTaskSchedules(props.selectedTeam.id, fcpIds);
    const goalPromise = addNewCoreRecurringGoalSchedules(props.selectedTeam.id, fcpIds);
    const triggerPromise = addNewCoreTriggerRules(props.selectedTeam.id, fcpIds);
    setIsAddMissingSubmitting(true);

    return Promise.all([recurringPromise, goalPromise, triggerPromise]).then((responses) => {
      const addedRecurringResponseData = responses[0].data;
      const addedGoalResponseData = responses[1].data;
      const addedTriggerResponseData = responses[2].data;
      let messageBody = <div>
        {addedRecurringResponseData && addedRecurringResponseData.length > 0 && <div>
          <div><strong>Created {addedRecurringResponseData.length} recurring task schedules:</strong></div>
          {addedRecurringResponseData.map((addedRecurringTaskSchedule) => {
            return <div>{addedRecurringTaskSchedule.templateTaskToCreate.summaryTemplate}</div>;
          })}
        </div>}
        {addedGoalResponseData && addedRecurringResponseData.length > 0 && <div>
          <div><strong>Created {addedGoalResponseData.length} recurring goal schedules:</strong></div>
          {addedGoalResponseData.map((addedRecurringGoalSchedule) => {
            return <div>{addedRecurringGoalSchedule.summaryTemplate}</div>;
          })}
        </div>}
        {addedRecurringResponseData && addedRecurringResponseData.length > 0 && <div>
          <div><strong>Created {addedTriggerResponseData.length} trigger rules:</strong></div>
          {addedTriggerResponseData.map((addedTriggerRule) => {
            return <div>{addedTriggerRule.templateTaskToCreate.summaryTemplate}</div>;
          })}
        </div>}
      </div>;

      addFeedbackMessage({
        key: createFeedbackMessageKey('coreAssignments', 'addMissing'),
        status: 'success',
        messageBody: messageBody,
      });
      setIsAddMissingModalOpen(false);
      setFcpsToAddMissing([]);
    }).finally(() => {
      setIsAddMissingSubmitting(false);
    });
  };

  const onAddMissingModalClosed = () => {
    setIsAddMissingModalOpen(false);
    setFcpsToAddMissing([]);
  };
  const onFcpsForMissingChange = (fcps: readonly IdOption[]) => {
    setFcpsToAddMissing(fcps);
  };

  return (
    <Container fluid className='d-flex flex-column flex-grow-1'>
      <Row>
        <Col></Col>
        <Col>
          <h4 className='text-center font-weight-bold'>Country Assignments</h4>
        </Col>
        <Col>
          <Button onClick={() => {
            setIsAddMissingModalOpen(true);
          }} style={{ marginLeft: 20 }}>
            <i className={'bi bi-arrow-clockwise'} /> Add missing Core Assignments
          </Button>
        </Col>
      </Row>
      {newTeamMemberId && (
        <Alert variant='info'>
          Almost done! Assign the new team member to recurring tasks, event triggered tasks, or goals.
          <br />
          Hit next (at the very bottom) to show the new team member's dashboard.
        </Alert>
      )}
      {isAddMissingSubmitting && <Spinner animation='border' />}
      {/* To be honest !isAddMissingSubmitting is a lazy way to get all of these grids to refresh */}
      {!isAddMissingSubmitting && <div>
        <h3 style={{ textAlign: 'center' }}>Recurring Tasks</h3>
        <div style={{ height: 400 }}>
          <RecurringTaskSchedulesGrid
            selectedTeam={props.selectedTeam}
            memberQuickSelect={true}
            idToFocusOn={recurringTaskScheduleIdToFocusOn}
          />
        </div>
        <h3 style={{ textAlign: 'center' }}>Goals</h3>
        <div style={{ height: 400 }}>
          <RecurringGoalScheduleGrid
            selectedTeam={props.selectedTeam}
            memberQuickSelect={true}
            idToFocusOn={recurringGoalScheduleIdToFocusOn}
          />
        </div>
        {/*<h3 style={{ textAlign: 'center' }}>Event Triggered Tasks</h3>*/}
        {/*<div style={{ height: 400 }}>*/}
        {/*<TriggerRuleGrid*/}
        {/*  selectedTeam={props.selectedTeam}*/}
        {/*  memberQuickSelect={true}*/}
        {/*  idToFocusOn={triggerRuleIdToFocusOn}*/}
        {/*/>*/}
        {/*</div>*/}
      </div>}
      <Row>
        {newTeamMemberId && (
          <Link to={'/?teamMember=' + newTeamMemberId}>
            <Button>Next</Button>
          </Link>
        )}
      </Row>
      <Modal show={isAddMissingModalOpen} onHide={onAddMissingModalClosed}>
        <Modal.Header closeButton>
          <Modal.Title>Add Missing Core Assignments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          What FCPs do you want to add core assignments for?
          <div>{fcpOptions.length === 0 && <Spinner animation={'border'} />}</div>
          {fcpOptions.length !== 0 && <Select
            placeholder={'All FCPs'}
            isMulti={true}
            // value={selectedMonthContext.selectedMonth}
            onChange={onFcpsForMissingChange}
            options={fcpOptions}
          />}
          <Alert variant='info'>
            If core assignments were previously deleted because they were unnecessary, this will add them again. Please
            delete them if they are still unnecessary.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onAddMissingModalClosed}>
            Cancel
          </Button>
          <Button variant='primary' onClick={() => addNewCoreAssignmentsClick(fcpsToAddMissing)}
                  disabled={isAddMissingSubmitting}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CountryAssignments;
