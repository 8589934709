import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import DefaultTooltip from '../DefaultTooltip/DefaultTooltip';

interface CoreSyncButtonProps {
  isAdapted: boolean;
  isOutdatedVersion: boolean;
  createdByCore: any;
  isUpdatingAdaptations: boolean;
  onReplaceAdaptedFields: () => void;
  isSubmitting: boolean;
  tooltipBody: JSX.Element;
}

const CoreSyncButton: React.FC<CoreSyncButtonProps> = ({
  isAdapted,
  isOutdatedVersion,
  createdByCore,
  isUpdatingAdaptations,
  onReplaceAdaptedFields,
  isSubmitting,
  tooltipBody,
}) => {
  if (!createdByCore || (!isAdapted && !isOutdatedVersion)) {
    return null;
  }

  let buttonLabel;
  let buttonVariant;
  if (isAdapted && isOutdatedVersion) {
    buttonLabel = 'Update - Warning: this will replace adaptations';
    buttonVariant = 'warning';
  } else if (isAdapted) {
    buttonLabel = 'Remove Adaptions, reset to global FCP';
    buttonVariant = 'danger';
  } else if (isOutdatedVersion) {
    buttonLabel = 'Update from global FCPs';
    buttonVariant = 'primary';
  } else {
    throw Error('Should be either adapted or outdated to show button');
  }

  return (
    <DefaultTooltip id={'adapted'} tooltipBodyElement={tooltipBody}>
      <div>
        <Button
          variant={buttonVariant}
          onClick={onReplaceAdaptedFields}
          disabled={isSubmitting || isUpdatingAdaptations}
        >
          {buttonLabel}
        </Button>
        {isUpdatingAdaptations && <Spinner animation='border' />}
      </div>
    </DefaultTooltip>
  );
};

export default CoreSyncButton; 