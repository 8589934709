// noinspection JSUnusedLocalSymbols

import React, { useCallback, useContext } from 'react';
import { GetRowIdParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import FeedbackMessagesContext from '../contexts/FeedbackMessagesContext';
import { AxiosResponse } from 'axios';
import { getObjectFromListById } from './ObjectIdHelpers';
import { doActionsContain } from './PermissionHelper';
import { ObjectWithActions, ObjectWithId } from '../domain/shared';
import TeamMemberType from '../domain/teamMember';
import { Modal, ModalBody } from 'react-bootstrap';
import { FeedbackMessage } from '../components/FeedbackMessages/FeedbackMessages';
import { getCallSignWithShortName } from './TeamMembershipHelpers';
import { Mode } from '../state_types/mode';

function getAssignedToString(assignedTo: TeamMemberType | null | undefined) {
  if (!assignedTo) {
    return 'UNASSIGNED!';
  } else if (!assignedTo.account || assignedTo.deleted) {
    return 'UNASSIGNED! - Was ' + getCallSignWithShortName(assignedTo);
  }
  return getCallSignWithShortName(assignedTo);
}

type GridComponentStates<DataType> = {
  gridApi: GridApi | null;
  setGridApi: (gridApi: GridApi) => void;

  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void;

  modifyDialogState: ModifyDialogState<DataType>;
  // null when creating
  openModifyDialog: (objectToModify: DataType | null) => void;
  closeModifyDialog: () => void;

  requestChangeDialogState: RequestChangeDialogState<DataType>;
  openRequestChangeDialog: (objectToModify: DataType) => void;
  closeRequestChangeDialog: () => void;

  deleteDialogState: DeleteDialogState<DataType>;
  openDeleteDialog: (objectToDelete: DataType) => void;
  disableSubmissionDeleteDialog: () => void;
  closeDeleteDialog: () => void;
};

type ModifyDialogState<DataType> = {
  isOpen: boolean;
  objectToModify: DataType | null;
};

const getMode = (state: ModifyDialogState<any>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

type RequestChangeDialogState<DataType> = {
  isOpen: boolean;
  objectToRequestChangeFor: DataType | null;
};

type DeleteDialogState<DataType> = {
  isSubmitting: boolean;
  isOpen: boolean;
  objectToDelete: DataType | null;
};

function getDefaultUseQueryConfig<DataType extends ObjectWithId & ObjectWithActions>(
  gridApi: GridApi | null,
  openModifyDialog: Function,
  idToFocusOn?: number,
  staleTime = 100000
) {
  return {
    staleTime: staleTime, // Cache for this long, in ms
    onSuccess: (response: AxiosResponse<DataType[]>) => {
      // If the grid isn't ready, this won't work,
      //  but the data will be picked up on onGridReady
      if (gridApi) {
        gridApi.setRowData(response.data);
        // Ideally I would use refreshCells here, and implement a refresh method in each cellRenderer.
        // However, I would then have to convert them from functional components to class components.
        // I prefer having all functional components and I'm also lazy.
        // This fixes cellRenderers that have change (link styling, red lines, warnings, etc)
        gridApi.redrawRows();
      }
      // setModifyDialogState causes a re-render, so this needs to be inside useQuery
      if (idToFocusOn && response?.data) {
        const foundObject = getObjectFromListById(response.data, idToFocusOn);
        if (foundObject && gridApi) {
          const rowNode = gridApi.getRowNode(foundObject.id.toString());
          if (rowNode && rowNode.rowIndex) {
            gridApi.ensureIndexVisible(rowNode.rowIndex);
            // This needs to be delayed until the grid is fully loaded
            setTimeout(() => {
              gridApi.flashCells({ rowNodes: [rowNode], flashDelay: 3000, fadeDelay: 10000 });
            }, 1000);
          }
        }
        if (foundObject && doActionsContain(foundObject, 'change')) {
          openModifyDialog(foundObject);
        } else if (foundObject) {
          console.debug('No edit permission ' + idToFocusOn);
        } else {
          console.error('No object found with id: ' + idToFocusOn);
        }
      }
    },
  };
}

function getDefaultColDef() {
  return {
    tooltipComponent: 'customTooltip',
    initialWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
    pinned: null, // important - clears pinned if not specified in col def
    sort: null, // important - clears sort if not specified in col def
    // 2022-18-07 Kirk, Austin, Brad decided that pinning, column toggles, etc are too complex.
    //  instead, only show the filter menu to have the least amount of confusion.
    menuTabs: ['filterMenuTab'],
  };
}

// Assumes:
//  - Object in grid has .id field (can typecheck this by making sure it subclasses ObjectWithId)
//  - Id will never be undefined or null
function defaultGetRowId(params: GetRowIdParams) {
  const objectWithId = params.data as ObjectWithId;
  return objectWithId.id.toString();
}

function defaultOnGridReady(
  params: GridReadyEvent,
  rowData: any[] | undefined,
  isRowsRefetching: boolean,
  setGridApi: (gridApi: GridApi) => void,
) {
  setGridApi(params.api);
  // If the tasks return before the grid is ready, this cleans it up
  if (rowData) {
    params.api.setRowData(rowData);
  }
  // This is needed when you do Dashboard -> complete task -> member scores -> reopen task -> Dashboard (and the tasks re-fetch)
  if (isRowsRefetching) {
    params.api.showLoadingOverlay();
  }
}

function useGridComponentStates<DataType>(): GridComponentStates<DataType> {
  // Weird syntax for types https://stackoverflow.com/a/53598521/13815107
  const [gridApi, setGridApi] = React.useState<null | GridApi>(null);
  const { addFeedbackMessage } = useContext(FeedbackMessagesContext);

  const [modifyDialogState, setModifyDialogState] = React.useState({
    isOpen: false,
    objectToModify: null,
  } as ModifyDialogState<DataType>);
  const openModifyDialog = useCallback((objectToModify: DataType | null) => {
    console.log('here');
    setModifyDialogState({
      isOpen: true,
      objectToModify: objectToModify,
    });
  }, []);
  const closeModifyDialog = () => {
    setModifyDialogState({
      isOpen: false,
      objectToModify: null,
    });
  };

  const [requestChangeDialogState, setRequestChangeDialogState] = React.useState({
    isSubmitting: false,
    isOpen: false,
    objectToRequestChangeFor: null,
  } as RequestChangeDialogState<DataType>);
  const openRequestChangeDialog = (objectToRequestChangeFor: DataType | null) => {
    setRequestChangeDialogState({
      isOpen: true,
      objectToRequestChangeFor: objectToRequestChangeFor,
    });
  };
  const closeRequestChangeDialog = () => {
    setRequestChangeDialogState({
      isOpen: false,
      objectToRequestChangeFor: null,
    });
  };

  const [deleteDialogState, setDeleteDialogState] = React.useState({
    isOpen: false,
    objectToDelete: null,
  } as DeleteDialogState<DataType>);
  const openDeleteDialog = (objectToDelete: DataType | null) => {
    setDeleteDialogState({
      isSubmitting: false,
      isOpen: true,
      objectToDelete: objectToDelete,
    });
  };
  const closeDeleteDialog = () => {
    setDeleteDialogState({
      isSubmitting: false,
      isOpen: false,
      objectToDelete: null,
    });
  };
  const disableSubmissionDeleteDialog = () => {
    setDeleteDialogState({
      isSubmitting: true,
      isOpen: true,
      objectToDelete: null,
    });
  };

  return {
    gridApi,
    setGridApi,

    addFeedbackMessage,

    modifyDialogState,
    openModifyDialog,
    // Formik handles disabling submission, no need for a disable method
    closeModifyDialog,

    requestChangeDialogState,
    openRequestChangeDialog,
    // Formik handles disabling submission, no need for a disable method
    closeRequestChangeDialog,

    deleteDialogState,
    openDeleteDialog,
    disableSubmissionDeleteDialog,
    closeDeleteDialog,
  };
}

function removeRowById(gridApi: GridApi, idToBeRemoved: number) {
  const rowNode = gridApi.getRowNode(idToBeRemoved.toString());
  if (!rowNode) {
    console.warn('No row found with id: ' + idToBeRemoved);
    return;
  }
  gridApi.applyTransaction({
    remove: [rowNode.data],
  });
}

function removeRow(gridApi: GridApi, objectToBeRemoved: ObjectWithId) {
  gridApi.applyTransaction({
    remove: [objectToBeRemoved],
  });
}

const getSimpleModal = (isOpen: boolean, handleCancel: () => void, title: string, text: string) => {
  // This component causes 'findDOMNode is deprecated in StrictMode' warning
  // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
  // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
  // TODO fix this warning
  return (
    <Modal show={isOpen} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <p>{text}</p>
      </ModalBody>
    </Modal>
  );
};

const getDefaultStatusBar = () => {
  return {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
      },
    ],
  };
};

export {
  getDefaultUseQueryConfig,
  getDefaultColDef,
  defaultGetRowId,
  useGridComponentStates,
  defaultOnGridReady,
  getAssignedToString,
  getSimpleModal,
  getDefaultStatusBar,
  getMode,
};

export { removeRow, removeRowById };
export type { DeleteDialogState, ModifyDialogState, RequestChangeDialogState, GridComponentStates };
