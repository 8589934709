import axios, { AxiosResponse } from 'axios';
import { RECURRING_GOAL_SCHEDULES_URL } from '../../constants/urls';
import RecurringGoalScheduleType from '../../domain/recurringGoalSchedule';

function replaceAdaptedGoalFieldsWithCore(recurringGoalSchedule: RecurringGoalScheduleType) {
  return axios.post<RecurringGoalScheduleType, AxiosResponse<RecurringGoalScheduleType>>(
    `${RECURRING_GOAL_SCHEDULES_URL}/${recurringGoalSchedule.id}/replace-adaptable-fields/`,
    recurringGoalSchedule,
  );
}

export {replaceAdaptedGoalFieldsWithCore}