import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styles from './HeaderNavLinks.module.scss';
import TeamType from '../../domain/team';
import { usePermissions } from '../../effects/usePermissions';

type HeaderNavLinksProps = {
  selectedTeam: TeamType | null;
};

// Should be authenticated to display this
const HeaderNavLinks = (props: HeaderNavLinksProps) => {
  const permissionResponse = usePermissions(props.selectedTeam);
  if (permissionResponse.isLoading) return <p>Loading...</p>;
  if (permissionResponse.error || !permissionResponse.permissionFlags) return <p>Error!</p>;

  const permissionFlags = permissionResponse.permissionFlags;

  // TODO add canViewFieldCoreProcesses
  // const fieldCoreProcessesPermission = true;
  // const fieldCoreProcessesPermission = permissionFlags.canViewFieldCoreProcesses;
  return (
    <Nav className="mr-auto">
      {permissionFlags.canViewDashboard && (
        <LinkContainer to="/" className={styles.linkContainer}>
          <Nav.Link className={styles.standoutLink}>Dashboard</Nav.Link>
        </LinkContainer>
      )}
      {permissionFlags.canViewCountryAssignments && (
        <LinkContainer to="country-assignments" className={styles.linkContainer}>
          <Nav.Link>Country Assignments</Nav.Link>
        </LinkContainer>
      )}
      {permissionFlags.canViewScores &&
        // Remove permissionFlags.canViewMemberScores and the below linkContainer when commenting in navDropdown
        permissionFlags.canViewMemberScores && (
          <LinkContainer to="member-scores" className={styles.linkContainer}>
            <Nav.Link>Scores</Nav.Link>
          </LinkContainer>
          // <NavDropdown title="Scores" id="scores-nav-dropdown">
          //   {permissionFlags.canViewMemberScores && (
          //     <LinkContainer to="member-scores" className={styles.linkContainer}>
          //       <NavDropdown.Item>Member Scores</NavDropdown.Item>
          //     </LinkContainer>
          //   )}
          //   {permissionFlags.canViewTeamScores && (
          //     <LinkContainer to="team-scores" className={styles.linkContainer}>
          //       <NavDropdown.Item>Team Scores</NavDropdown.Item>
          //     </LinkContainer>
          //   )}
          // </NavDropdown>
        )}

      {permissionFlags.canViewFcpAssignments && (
        <LinkContainer to="field-core-assignments" className={styles.linkContainer}>
          <Nav.Link>FCP Assignments</Nav.Link>
        </LinkContainer>
      )}

      {/*{fieldCoreProcessesPermission && <FcpDropdown />}*/}

      {/* This one doesn't actually work, I want it to route to the admin page served by Django */}
      {/* <Nav.Link href="/ops-management/admin">Manage users <i className="bi-box-arrow-up-right"></i></Nav.Link> */}

      {/*{props.selectedTeam && canCreateSomething && (*/}
      {/*  <CreateSomething selectedTeam={props.selectedTeam} />*/}
      {/*)}*/}
    </Nav>
  );
};

export default HeaderNavLinks;
