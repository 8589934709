import { ObjectWithActions, ObjectWithId } from './shared';
import TeamMemberType from './teamMember';
import TeamType from './team';
import { roundTo2Decimals, roundTo4Decimals } from '../helpers/RoundingHelper';
import CoreRecurringGoalScheduleType from './coreRecurringGoalSchedule';

type RecurringGoalScheduleAssignmentType = ObjectWithId & {
  assignedToMember: TeamMemberType;
  totalPoints: number;
};

type RecurringGoalScheduleAssignmentTypeToSend = ObjectWithId & {
  assignedToMember: number;
  totalPoints: number;
};

function getDisplayValue(backendValue: number | undefined | null, unitOfMeasure: string | undefined | null) {
  if (backendValue === undefined || backendValue === null) {
    return undefined;
  }
  if (unitOfMeasure === 'percent') {
    return roundTo2Decimals(backendValue * 100.0);
  } else {
    return backendValue;
  }
}

function getValueToSend(displayValue: number | undefined | null, unitOfMeasure: string | undefined) {
  if (displayValue === undefined || displayValue === null) {
    return undefined;
  }
  if (unitOfMeasure === 'percent') {
    return roundTo4Decimals(displayValue / 100.0);
  } else {
    return displayValue;
  }
}

type RecurringGoalScheduleType = ObjectWithId &
  ObjectWithActions & {
    frequency: string;
    startDateTime: string;

    summaryTemplate: string;
    linkUrlTemplate: string;
    descriptionTemplate: string;

    scoringType: string;
    totalPoints: number;
    sourceField: number;
    goalValue: number;
    cutoffValue: number;
    betterDirection: string;
    unitOfMeasure: string;

    enableAutoPush: boolean;
    allowAutoPush: boolean;
    autoPushExceedByDeltaValue?: number;
    autoPushChangeDeltaValue?: number;
    autoPushBestExpectedValue?: number;

    team: TeamType;
    assignedToMember: TeamMemberType | null | undefined;

    secondaryAssignedToMembers: RecurringGoalScheduleAssignmentType[];

    createdByCore: CoreRecurringGoalScheduleType;
    createdByCoreVersion: CoreRecurringGoalScheduleType;
    isAdapted: boolean;
    adaptations: {
      summaryTemplate: boolean;
      linkUrlTemplate: boolean;
      descriptionTemplate: boolean;
      totalPoints: boolean;
      frequency: boolean;
      startDateTime: boolean;

      scoringType: boolean;
      sourceField: boolean;
      goalValue: boolean;
      cutoffValue: boolean;
    }
    isOutdatedCoreVersion: boolean;
  };

type RecurringGoalScheduleTypeToSend = {
  id?: number;

  frequency?: string;
  startDateTime?: string;

  summaryTemplate?: string;
  linkUrlTemplate?: string;
  descriptionTemplate?: string;

  scoringType?: string;
  totalPoints?: number;
  sourceField?: number;
  goalValue?: number;
  cutoffValue?: number;

  enableAutoPush: boolean;

  team?: number;
  assignedToMember?: number;
  actions?: string[];

  secondaryAssignedToMembers: RecurringGoalScheduleAssignmentTypeToSend[];

  // Not sent to backend
  // TODO this is very hacky

  _unitOfMeasure?: string;
};

function convertSecondaryAssignedToMembersTypeToSend(
  recurringSecondaryAssignedToMembers: RecurringGoalScheduleAssignmentType[],
) {
  return recurringSecondaryAssignedToMembers.map((assignment) => {
    return {
      ...assignment,
      assignedToMember: assignment.assignedToMember?.id,
    };
  });
}

function convertToTypeToDisplay(recurringGoalSchedule: RecurringGoalScheduleType): RecurringGoalScheduleTypeToSend {
  const secondaryAssignedToMembersToSend = convertSecondaryAssignedToMembersTypeToSend(
    recurringGoalSchedule.secondaryAssignedToMembers,
  );
  // Convert objects into ids while preserving the other fields
  const recurringTaskScheduleToDisplay = {
    ...recurringGoalSchedule,
    team: recurringGoalSchedule.team.id,
    // Sometimes undefined/null, especially after installing a new FCP
    assignedToMember: recurringGoalSchedule.assignedToMember?.id,
    secondaryAssignedToMembers: secondaryAssignedToMembersToSend,
  } as RecurringGoalScheduleTypeToSend;
  return recurringTaskScheduleToDisplay;
}

function convertToTypeToSend(
  recurringGoalScheduleToDisplay: RecurringGoalScheduleTypeToSend,
  valueUnitOfMeasure: string | undefined,
): RecurringGoalScheduleTypeToSend {
  const recurringTaskScheduleToSend = {
    ...recurringGoalScheduleToDisplay,
    goalValue: getValueToSend(recurringGoalScheduleToDisplay.goalValue, valueUnitOfMeasure),
    cutoffValue: getValueToSend(recurringGoalScheduleToDisplay.cutoffValue, valueUnitOfMeasure),
  };
  return recurringTaskScheduleToSend;
}

export default RecurringGoalScheduleType;
export type { RecurringGoalScheduleTypeToSend };
export {
  convertToTypeToDisplay,
  convertSecondaryAssignedToMembersTypeToSend,
  convertToTypeToSend,
  getDisplayValue,
  getValueToSend,
};
